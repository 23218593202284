// EmotionsFeltDisplay.js
import React, { useState, useMemo } from "react";
import moment from "moment";
import { NoData } from "../screens/StudentData/components/AlertComponents";

const EmotionsFeltDisplay = ({ filteredNextStepsData, topEmotions }) => {
  const [hoveredEmotion, setHoveredEmotion] = useState(null);

  const emotionsArray = useMemo(() => {
    if (topEmotions && Object.keys(topEmotions).length > 0) {
      return Object.entries(topEmotions);
    } else if (filteredNextStepsData && filteredNextStepsData.length > 0) {
      const emotionsFeltCounts = {};
      // const now = moment();

      filteredNextStepsData.forEach((dataPoint) => {
        // const dataDate = moment(dataPoint.date);
        // if (dataDate.isAfter(now.clone().subtract(30, "days"))) {
        //   emotionsFeltCounts[dataPoint.emotion] = (emotionsFeltCounts[dataPoint.emotion] || 0) + 1;
        // }
        emotionsFeltCounts[dataPoint.emotion] = (emotionsFeltCounts[dataPoint.emotion] || 0) + 1;

      });

      return Object.entries(emotionsFeltCounts).sort(([, a], [, b]) => b - a);
    }
    return [];
  }, [filteredNextStepsData, topEmotions]);

  const emotionsDisplayLength = Math.min(emotionsArray.length, 3);

  if (emotionsArray.length === 0) {
    return <NoData></NoData>;
  }

  return (
    <div className="emotions-felt-display-container">
      {emotionsArray.slice(0, emotionsDisplayLength).map(([emotion, count]) => (
        <div 
          className="emotion-icon-stretch" 
          key={emotion}
          onMouseEnter={() => setHoveredEmotion(emotion)}
          onMouseLeave={() => setHoveredEmotion(null)}
        >
          <div className={`emotion-image-container ${hoveredEmotion === emotion ? 'hovered' : ''}`}>
            <img 
              src={`../images/emotions/${emotion}.png`} 
              alt={emotion} 
              className="emotion-image-next-steps"
            />
            {hoveredEmotion === emotion && (
              <div className="hover-text">
                {emotion}
              </div>
            )}
          </div>
          <p className="emotion-count">{count} {count > 1 ? 'times' : 'time'}</p>
        </div>
      ))}
    </div>
  );
};

export default EmotionsFeltDisplay;