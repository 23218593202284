import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

const ScreenshotButtonComponent = ({ studentDataScreen = false, congratulationsBar = false }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleScreenshot = async () => {
    setIsLoading(true);
    try {
      const canvas = await html2canvas(document.body);
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      
      const link = document.createElement('a');
      link.download = 'screenshot.png';
      link.href = image;
      link.click();
      
    } catch (error) {
      console.error('Failed to take screenshot:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPositionClass = () => {
    if (congratulationsBar) return 'lowest-position';
    if (studentDataScreen) return 'lower-position';
    return '';
  };

  return (
    <div style={{ position: 'relative' }}>
      <button 
        className={`faDownload ${getPositionClass()}`} 
        onClick={handleScreenshot} 
        disabled={isLoading}
        style={{ position: 'absolute', top: 0, right: 0 }}
      >
        <FontAwesomeIcon icon={faFileArrowDown} />
      </button>
    </div>
  );
};

export default ScreenshotButtonComponent;