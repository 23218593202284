import React from "react";
import WellnessBadges from "./WellnessBadges";
import WellnessCoins from "./WellnessCoins";
import WellnessStreaks from "./WellnessStreaks";
import "./AchievementPage.css";

const AchievementPage = (props) => {
    const userName = props.user.displayName ? props.user.displayName : props.user.email
    return(
        <div className="achievementPageContainer">
            <p className="congratsSubheader">Congratulations {userName}, your achievements are adding up!</p>
            <WellnessCoins coins={props.user.coins}></WellnessCoins>
            <WellnessStreaks curDayStreak={props.user.curDayStreak}
                             curWeekStreak={props.user.curWeekStreak}
                             maxDayStreak={props.user.maxDayStreak}
                             maxWeekStreak={props.user.maxWeekStreak}>
            </WellnessStreaks>
            <WellnessBadges badges={props.user.badges}></WellnessBadges>
        </div>
    )
}

export default AchievementPage