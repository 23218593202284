import "./Actions.css";
import "./PerformAction.css";
import BlueSidebar from "../../components/BlueSidebar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { useContext, useEffect, useState } from "react";
import {Box, LinearProgress } from "@mui/material";
import { Context3 } from "../../components/Timer";
// import ReactPlayer from "react-player/lazy";
// import { Context2 } from "../../components/Audio";


const PerformAction = (props) => {
  const navigate = useNavigate();
  // const [playing, setPlaying] = useContext(Context2);
  // const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const [string, setString] = useState("");
  const [progress, setProgress] = useState(2);
  const time = props.user.user.wellnesstimer;

  var imageSettingFilenameModifier = null
  if (props.user.user.adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }

  useEffect(() => {
    var timerlength = 0;
    if (time === "1 minute") {
      timerlength = 60000;
    } else if (time === "2 minutes") {
      timerlength = 120000;
    } else if (time === "30 seconds") {
      timerlength = 30000;
    }else if (time === "20 seconds") {
      timerlength = 20000;
    }else if (time === "10 seconds") {
      timerlength = 10000;
    }
    const progresstimer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, timerlength/100);
    const timer = setTimeout(() => {
      navigate("/congratulations");
    }, timerlength);
    const action = wellnessDataPoint.action;
    const otherCheck = action.split(" ");
    var temp;
    if (otherCheck[0] === "Other:") {
      temp = "../images/actions/Other_BIG_FONT.png";
    } else {temp = `../images/actions/${wellnessDataPoint.action} ${imageSettingFilenameModifier}.png`;}
    setString(temp);
    return () => {
      clearTimeout(timer);
      clearInterval(progresstimer);
    };
  }, []);

  return (
    <motion.div
      class="outerperformaction"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      {/* <div class='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/actions.wav"} />
      </div> */}
      <div class="classandactions">
        <div class="practicewellnesstext">
          Please take {time} to practice wellness{" "}
        </div>
        {wellnessDataPoint.action.substring(0, 6) === "Other:" && (
          <div class="altwellness">
            You chose your own wellness practice:{" "}
            {wellnessDataPoint.action.substring(6)}
          </div>
        )}
        <img class="chosenaction" src={string} alt="loading..." />
        <Box className='linearprogresspractice' >
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </div>
    </motion.div>
  );
};

export default PerformAction;
