// TODO: Ideally replace this w/ a TS type / interface
export const Class = {
  _id: "id",
  district: "string",
  name: "string",
  school: "string",
  students: "Array<string>",
  teachers: "Array<string>",
  cleverId: "string",
  archived: "boolean",
};
