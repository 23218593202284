import React, {useState} from 'react'

const initialState = {
    action:'',
    emotion:'',
    date:'',
    helpful:false,
};

export const Context = React.createContext();

const Store = ({children}) => {
    const [wellnessDataPoint,setWellnessDataPoint] = useState(initialState);

    return(
        <Context.Provider value={[wellnessDataPoint,setWellnessDataPoint]}>{children}</Context.Provider>
    );
};

export default Store;