import { Route, Routes, useLocation } from "react-router-dom";
import Actions from "../screens/HomeScreen/Actions";
import Congratulations from "../screens/HomeScreen/Congratulations";
import DeepBreathe from "../screens/HomeScreen/DeepBreathe";
import DeepBreathe2 from "../screens/HomeScreen/DeepBreathe2";
import DeepBreathe3 from "../screens/HomeScreen/DeepBreathe3";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import HowDoIFeel from "../screens/HomeScreen/HowDoIFeel";
import SeeAndHear from "../screens/HomeScreen/SeeAndHear";
import SeeAndHear2 from "../screens/HomeScreen/SeeAndHear2";
// import ProfileScreen from "../screens/ProfileScreen/ProfileScreen";
import MyStory from "../screens/MyStory/MyStory";
import { AnimatePresence } from "framer-motion";
import Store from "./Store";
import Audio from "./Audio";
import OptionsScreen from "../screens/OptionsScreen/OptionsScreen";
import BlueSidebar from "./BlueSidebar";
import Sidebar from "./Sidebar";
import PerformAction from "../screens/HomeScreen/PerformAction";
import Timer from "./Timer";
import AudioScreen from "../screens/HomeScreen/AudioScreen";
import DeepBreatheCopy from "../screens/HomeScreen/DeepBreathe copy";
import SuperAdminDataTrendsScreen from "../screens/SuperAdminUser/SuperAdminDataTrendsScreen/SuperAdminDataTrendsScreen";
import WellnessAdministratorScreen from "../screens/123WellnessUser/WellnessAdministratorScreen/WellnessAdministratorScreen";
import SchoolsScreen from "../screens/SuperAdminUser/SchoolsScreen/SchoolsScreen";
import DataTrendsScreen from "../screens/AdminAndTeacherUser/DataTrendsScreen/DataTrendsScreen";
import StudentsScreen from "../screens/AdminAndTeacherUser/StudentsScreen/StudentsScreen";
import ClassScreen from "../screens/AdminAndTeacherUser/StudentsScreen/ClassScreen";
import WellnessDataTrendsScreen from "../screens/123WellnessUser/WellnessDataTrendsScreen/WellnessDataTrendsScreen";
import WellnessStudentsScreen from "../screens/123WellnessUser/WellnessStudentsScreen/WellnessStudentsScreen";
import { useState } from "react";
import ManageCleverClasses from "../screens/AdminAndTeacherUser/StudentsScreen/ManageCleverClasses";
import StudentsScreenV2 from "../screens/AdminAndTeacherUser/StudentsScreen/StudentsScreenV2";
import StudentData from "../screens/StudentData/StudentData";

function AnimatedRoutes(props) {
  //console.log(props.user.user)
  const location = useLocation();
  const [wellnessId, setWellnessId] = useState(0);
  const [chosenClass, setChosenClass] = useState("Choose Class");
  const [chosenTime, setChosenTime] = useState("month");

  return (
    <>
      <Store>
        <Audio>
          <Timer>
            <AnimatePresence>
              {location.pathname === "/" ||
              location.pathname === "/profilescreen" ||
              //location.pathname === "/congratulations" ||
              location.pathname === "/optionsscreen" ||
              location.pathname === "/classscreen" ||
              location.pathname === "/datatrends" ||
              location.pathname === "/schoolsscreen" ||
              location.pathname === "/superadmindatatrends" ||
              location.pathname === "/wellnessadministrator" ||
              location.pathname === "/wellnessdatatrends" ||
              location.pathname === "/wellnessstudents" ||
              location.pathname === "/studentsscreen" ||
              location.pathname === "/viewclassdata" ? (
                <Sidebar user={props.user}></Sidebar>
              ) : (
                <BlueSidebar user={props.user}></BlueSidebar>
              )}
              {props.user.user.user === "student" && (
                <Routes key={location.pathname} location={location}>
                  <Route path="/" element={<HomeScreen user={props.user} />} />
                  <Route
                    path="/performaction"
                    element={
                      <PerformAction
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/profilescreen"
                    element={<MyStory user={props.user} />}
                  />
                  <Route
                    path="/optionsscreen"
                    element={
                      <OptionsScreen
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/deepbreathe"
                    element={<DeepBreathe user={props.user} />}
                  />
                  <Route
                    path="/deepbreathecopy"
                    element={<DeepBreatheCopy user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe2"
                    element={<DeepBreathe2 user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe3"
                    element={<DeepBreathe3 user={props.user} />}
                  />
                  <Route
                    path="/seeandhear"
                    element={<SeeAndHear user={props.user} />}
                  />
                  <Route
                    path="/seeandhear2"
                    element={<SeeAndHear2 user={props.user} />}
                  />
                  <Route
                    path="/howdoifeel"
                    element={<HowDoIFeel user={props.user} />}
                  />
                  <Route
                    path="/actions"
                    element={
                      <Actions
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                        lSetWellnessId={setWellnessId}
                      />
                    }
                  />
                  <Route path="/audioscreen" element={<AudioScreen />} />
                  <Route
                    path="/congratulations"
                    element={
                      <Congratulations
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                </Routes>
              )}
              {props.user.user.user === "teacher" && (
                <Routes key={location.pathname} location={location}>
                  <Route path="/" element={<HomeScreen user={props.user} />} />

                  <Route
                    path="/viewclassdata"
                    element={
                      <StudentData
                        user={props.user}
                        setUser={props.setUser}
                      />
                      // <ClassScreen
                      //   user={props.user}
                      //   chosenTime={chosenTime}
                      //   chosenClass={chosenClass}
                      //   setChosenTime={setChosenTime}
                      //   setChosenClass={setChosenClass}
                      // />
                    }
                  />
                  <Route
                    path="/performaction"
                    element={
                      <PerformAction
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/profilescreen"
                    element={<MyStory user={props.user} />}
                  />
                  <Route
                    path="/optionsscreen"
                    element={
                      <OptionsScreen
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/deepbreathe"
                    element={<DeepBreathe user={props.user} />}
                  />
                  <Route
                    path="/deepbreathecopy"
                    element={<DeepBreatheCopy user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe2"
                    element={<DeepBreathe2 user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe3"
                    element={<DeepBreathe3 user={props.user} />}
                  />
                  <Route
                    path="/seeandhear"
                    element={<SeeAndHear user={props.user} />}
                  />
                  <Route
                    path="/seeandhear2"
                    element={<SeeAndHear2 user={props.user} />}
                  />
                  <Route
                    path="/howdoifeel"
                    element={<HowDoIFeel user={props.user} />}
                  />
                  <Route
                    path="/actions"
                    element={
                      <Actions
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                        lSetWellnessId={setWellnessId}
                      />
                    }
                  />
                  <Route path="/audioscreen" element={<AudioScreen />} />

                  <Route
                    path="/congratulations"
                    element={
                      <Congratulations
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/classscreen"
                    element={
                      <StudentsScreenV2
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  {/* <Route
                    path="/classscreen"
                    element={
                      <StudentsScreenV2
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  /> */}
                </Routes>
              )}
              {props.user.user.user === "admin" && (
                <Routes key={location.pathname} location={location}>
                  <Route path="/" element={<HomeScreen user={props.user} />} />
                  <Route
                    path="/performaction"
                    element={
                      <PerformAction
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/profilescreen"
                    element={<MyStory user={props.user} />}
                  />
                  <Route
                    path="/optionsscreen"
                    element={
                      <OptionsScreen
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/deepbreathe"
                    element={<DeepBreathe user={props.user} />}
                  />
                  <Route
                    path="/deepbreathecopy"
                    element={<DeepBreatheCopy user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe2"
                    element={<DeepBreathe2 user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe3"
                    element={<DeepBreathe3 user={props.user} />}
                  />
                  <Route
                    path="/seeandhear"
                    element={<SeeAndHear user={props.user} />}
                  />
                  <Route
                    path="/seeandhear2"
                    element={<SeeAndHear2 user={props.user} />}
                  />
                  <Route
                    path="/howdoifeel"
                    element={<HowDoIFeel user={props.user} />}
                  />
                  <Route
                    path="/actions"
                    element={
                      <Actions
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                        lSetWellnessId={setWellnessId}
                      />
                    }
                  />
                  <Route path="/audioscreen" element={<AudioScreen />} />

                  <Route
                    path="/congratulations"
                    element={
                      <Congratulations
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/studentsscreen"
                    element={
                      <StudentsScreenV2
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/classscreen"
                    element={
                      <StudentsScreenV2
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/datatrends"
                    element={<StudentData user={props.user} />}
                  />
                </Routes>
              )}
              {props.user.user.user === "superadmin" && (
                <Routes key={location.pathname} location={location}>
                  <Route path="/" element={<HomeScreen user={props.user} />} />
                  <Route
                    path="/performaction"
                    element={
                      <PerformAction
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/profilescreen"
                    element={<MyStory user={props.user} />}
                  />
                  <Route
                    path="/optionsscreen"
                    element={
                      <OptionsScreen
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/deepbreathe"
                    element={<DeepBreathe user={props.user} />}
                  />
                  <Route
                    path="/deepbreathecopy"
                    element={<DeepBreatheCopy user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe2"
                    element={<DeepBreathe2 user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe3"
                    element={<DeepBreathe3 user={props.user} />}
                  />
                  <Route
                    path="/seeandhear"
                    element={<SeeAndHear user={props.user} />}
                  />
                  <Route
                    path="/seeandhear2"
                    element={<SeeAndHear2 user={props.user} />}
                  />
                  <Route
                    path="/howdoifeel"
                    element={<HowDoIFeel user={props.user} />}
                  />
                  <Route
                    path="/actions"
                    element={
                      <Actions
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                        lSetWellnessId={setWellnessId}
                      />
                    }
                  />
                  <Route path="/audioscreen" element={<AudioScreen />} />

                  <Route
                    path="/congratulations"
                    element={
                      <Congratulations
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/schoolsscreen"
                    element={<StudentsScreenV2 user={props.user} />}
                  />
                  <Route
                    path="/superadmindatatrends"
                    element={<StudentData user={props.user} />}
                  />
                </Routes>
              )}
              {props.user.user.user === "123wellness" && (
                <Routes key={location.pathname} location={location}>
                  <Route path="/" element={<HomeScreen user={props.user} />} />
                  <Route
                    path="/performaction"
                    element={
                      <PerformAction
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/profilescreen"
                    element={<MyStory user={props.user} />}
                  />
                  <Route
                    path="/congratulations"
                    element={
                      <Congratulations
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                      />
                    }
                  />
                  <Route
                    path="/optionsscreen"
                    element={
                      <OptionsScreen
                        user={props.user}
                        setUser={props.setUser}
                      />
                    }
                  />
                  <Route
                    path="/deepbreathe"
                    element={<DeepBreathe user={props.user} />}
                  />
                  <Route
                    path="/deepbreathecopy"
                    element={<DeepBreatheCopy user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe2"
                    element={<DeepBreathe2 user={props.user} />}
                  />
                  <Route
                    path="/deepbreathe3"
                    element={<DeepBreathe3 user={props.user} />}
                  />
                  <Route
                    path="/seeandhear"
                    element={<SeeAndHear user={props.user} />}
                  />
                  <Route
                    path="/seeandhear2"
                    element={<SeeAndHear2 user={props.user} />}
                  />
                  <Route
                    path="/howdoifeel"
                    element={<HowDoIFeel user={props.user} />}
                  />
                  <Route
                    path="/actions"
                    element={
                      <Actions
                        setUser={props.setUser}
                        user={props.user}
                        lWellnessId={wellnessId}
                        lSetWellnessId={setWellnessId}
                      />
                    }
                  />
                  <Route path="/audioscreen" element={<AudioScreen />} />
                  <Route
                    path="/wellnessadministrator"
                    element={<WellnessAdministratorScreen user={props.user} />}
                  />
                  <Route
                    path="/wellnessdatatrends"
                    element={<StudentData user={props.user} />}
                  />
                  <Route
                    path="/wellnessstudents"
                    // element={<WellnessStudentsScreen user={props.user} />}
                    element={<StudentsScreenV2 user={props.user} />}
                  />
                </Routes>
              )}
            </AnimatePresence>
          </Timer>
        </Audio>
      </Store>
    </>
  );
}

export default AnimatedRoutes;
