import React from "react";
import { timeframeOptions } from "../../../components/timeframeOptions";
import { handleTime } from "../handlers/timeHandler";

const TimeDropdown = ({ state, updateState }) => (
  <select
    className="filterprofiledatasuper"
    value={state.chosenTime}
    onChange={(e) => updateState(handleTime(state, e.target.value, false))}
  >
    {timeframeOptions.map((option) => (
      <option key={option.value} value={option.value}>
        {option.display}
      </option>
    ))}
  </select>
);

export default TimeDropdown;
