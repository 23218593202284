import React, { useEffect, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./MyStory.css";
import "../ProfileScreen/ProfileCalendar.css";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import { Popover } from "react-tiny-popover";
import { useContext } from "react";
import { UserContext } from "../../realm/user.context";
import {
  getWellnessDataByUserID,
  updateWellnessHelpfulMutation,
} from "../../realm/graphqlQueries";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import DateframeDropdownSelect from "../../components/DateframeDropdownSelect";
import MyWellnessSuccessTab from "../../components/MyWellnessSuccessTab";
import MyNextSteps from "../../components/MyNextSteps";
import NavigationBar from "../StudentData/NavigationBar";
import CongratulationsBanner from "../StudentData/CongratulationsBanner";
import BarGraph from "../../components/BarGraph";
import BigFiveWellnessDataWrapper from "../../components/BigFiveWellnessData";
import AchievementPage from "../../components/AchievementPage/AchievementPage";
import ScreenshotButtonComponent from "../../components/ScreenshotButtonComponent";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import { timeHelper } from "../StudentData/utils/timeHelper";
import { useLocation } from "react-router-dom";


const MyStory = (props) => {
  const navigate = useNavigate();
  const { realmUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [wellnessList, setWellnessList] = useState([]);
  const [filteredNextStepsData, setFilteredNextStepsData] = useState([]);
  const [bigFiveFilteredData, setBigFiveFilteredData] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState({
    "All Day": false,
    "Early Morning": false,
    Morning: false,
    Afternoon: false,
    Evening: false,
  });

  const [helpfulFilter, setHelpfulFilter] = useState("All");
  const [selectedSubOptions, setSelectedSubOptions] = useState({});
  const localizer = momentLocalizer(moment);
  const [state, setState] = useState({
    favourites: false,
    sad: true,
    tired: true,
    lonely: true,
    happy: true,
    grateful: true,
    calm: true,
    stressed: true,
    scared: true,
    angry: true,
  });
  const {
    favourites,
    sad,
    tired,
    lonely,
    happy,
    grateful,
    calm,
    stressed,
    scared,
    angry,
  } = state;

  const [allData, setAllData] = useState(null);
  const [filterName, setFilterName] = useState("week");
  const [filterAmount, setFilterAmount] = useState(moment.duration(7, "days"));
  const [wellnessPopupOpen, setWellnessPopupOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  // what helps vars
  const [whatHelpsData, setWhatHelpsData] = useState({});
  const [selectedEmotion, setSelectedEmotion] = useState("all");
  const [selectedEmotionColor, setSelectedEmotionColor] = useState("#C8FADD"); // green
  const [selectedTimeframe, setSelectedTimeframe] = useState("week");

  var [feeling, setFeeling] = useState("select");

  useEffect(() => {
    fetchAllData(props.user.user, realmUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allData) return;
    const whatHelps = allData
      .filter(
        (data) =>
          (data.emotion === selectedEmotion || selectedEmotion === "all") &&
          moment(data.date).isAfter(
            moment().subtract(timeHelper(selectedTimeframe))
          ) &&
          data.helpful === true
      )
      .map((item) => {
        const date = new Date(item.date);
        const hour = date.getUTCHours();
        return { ...item, hourfield: hour };
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
    setWhatHelpsData(whatHelps);
  }, [selectedEmotion, selectedEmotionColor, selectedTimeframe]);

  useEffect(() => {
    changeBarGraphFilter(filterName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wellnessList]);

  useEffect(() => {
    changeBigFiveWellnessFilters();
  }, [helpfulFilter, selectedSubOptions, selectedTimes, filterName]);


  const tempdata = [
    {
      name: "sad",
      count: 0,
    },
    {
      name: "tired",
      count: 0,
    },
    {
      name: "lonely",
      count: 0,
    },
    {
      name: "happy",
      count: 0,
    },
    {
      name: "grateful",
      count: 0,
    },
    {
      name: "calm",
      count: 0,
    },
    {
      name: "stressed",
      count: 0,
    },
    {
      name: "scared",
      count: 0,
    },
    {
      name: "angry",
      count: 0,
    },
  ];
  const [studentData, setStudentData] = useState([]);
  const [barGraphData, setBarGraphData] = useState(tempdata);
  const [tally, setTally] = useState(0);
  const [currentTabNumber, setCurrentTabNumber] = useState(0);
  const timeframeToDayConversionDict = {
    day: 1,
    week: 7,
    month: 30,
    "3 months": 90,
    "6 months": 182,
    year: 365,
  };

  function calculateTotal(dict) {
    var testCount = 0;
    Object.values(dict).forEach((value) => {
      testCount += value;
    });
    return testCount;
  }

  function gatherWellnessPractices(feeling, time) {
    setFilterName(time);
    
    let filter = moment.duration(timeframeToDayConversionDict[time], "days");
    
    let filteredData = [];
    let emotionDict = {
      sad: {},
      tired: {},
      lonely: {},
      happy: {},
      grateful: {},
      calm: {},
      stressed: {},
      scared: {},
      angry: {},
    };
  
    studentData.forEach((datapoint) => {
      if (new Date(datapoint.date).getTime() >= moment().subtract(filter).valueOf()) {
        let feeling = datapoint.emotion;
        let helped = datapoint.helpful;
        let action = datapoint.action;
  
        if (helped) {
          if (!(action in emotionDict[feeling])) {
            emotionDict[feeling][action] = 0;
          }
          emotionDict[feeling][action]++;
        }
      }
    });
  
    var feelingList;
    setFeeling(feeling);
    if (feeling === "select") {
      feelingList = [];
    } else {
      feelingList = Array.from(Object.keys(emotionDict[feeling]));
    }
  
    if (feelingList.length > 3) {
      let objectList = Object.keys(emotionDict[feeling]).sort(function (a, b) {
        return emotionDict[feeling][b] - emotionDict[feeling][a];
      });
      setWellnessList(objectList.slice(0, 3));
    } else {
      setWellnessList(feelingList);
    }
  
    setBigFiveFilteredData(studentData.filter(
      (data) =>
        new Date(data.date).getTime() >= moment().subtract(filter).valueOf()
    ));
  }

  async function fetchAllData(user, realmUser) {
    setTally(0);
    setLoading(true);
    const resp = await getWellnessDataByUserID(user._id, realmUser);
    let bargraphdata;
    let dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };

    resp
      .filter(
        (data) =>
          new Date(data.date).getTime() >=
          moment().subtract(moment.duration(7, "days")).valueOf()
      )
      .forEach((datapoint) => {
        dict[datapoint.emotion] += 1;
      });

    bargraphdata = Object.keys(dict).map((key) => ({
      name: key,
      count: dict[key],
    }));

    var studentDataArrayWithHours = [];
    resp.forEach((item) => {
      const date = new Date(item.date);
      const hour = date.getUTCHours();
      studentDataArrayWithHours.push({ ...item, hourfield: hour });
    });

    const whatHelps = resp
      .filter(
        (data) =>
          (data.emotion === selectedEmotion || selectedEmotion === "all") &&
          moment(data.date).isAfter(
            moment().subtract(timeHelper(selectedTimeframe))
          ) &&
          data.helpful === true
      )
      .map((item) => {
        const date = new Date(item.date);
        const hour = date.getUTCHours();
        return { ...item, hourfield: hour };
      });

      const nextSteps = resp
      .filter(
        (data) => (
          moment(data.date).isAfter(
            moment().subtract(timeHelper(filterName))
          ) &&
          data.helpful === true
      ));

    setAllData(resp);
    setFilteredNextStepsData(nextSteps);
    setTally(calculateTotal(dict));
    setBarGraphData(bargraphdata);
    setStudentData(studentDataArrayWithHours);
    setWhatHelpsData(whatHelps);
    setLoading(false);
  }

  function getDate(data) {
    return moment(data).format("l");
  }
  function getTime(data) {
    return moment(data).format("LT");
  }
  function getColor(emotion) {
    if (emotion === "happy" || emotion === "grateful" || emotion === "calm")
      return "#A5E2C6";
    else if (
      emotion === "stressed" ||
      emotion === "scared" ||
      emotion === "angry"
    )
      return "#DEC8F1";
    else if (emotion === "sad" || emotion === "tired" || emotion === "lonely")
      return "#ADCDEA";
  }
  function getImage(emotion) {
    return "../images/emotions/" + emotion + ".png";
  }
  function getHeart(helpful) {
    if (helpful) return <FaHeart style={{ fontSize: "1.3vmax" }}></FaHeart>;
    else return <FaRegHeart style={{ fontSize: "1.3vmax" }}></FaRegHeart>;
  }
  function changeBarGraphFilter(time) {
    setTally(0);
    var filter = moment.duration(7, "days");
    filter = moment.duration(timeframeToDayConversionDict[time], "days");
    var bargraphdata;
    var dict = {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
    let filteredData = [];
    studentData
      .filter(
        (data) =>
          new Date(data.date).getTime() >= moment().subtract(filter).valueOf()
      )
      .forEach((datapoint) => {
        filteredData.push(datapoint);
        dict[datapoint.emotion] += 1;
        setTally((tally) => tally + 1);
      });
    bargraphdata = Object.keys(dict).map((key) => ({
      name: key,
      count: dict[key],
    }));
    setFilteredNextStepsData(filteredData);
    setFilterAmount(filter);
    setFilterName(time);
    setBarGraphData(bargraphdata);
  }
  const events = studentData.map((data) => {
    return {
      start: moment(data.date).toDate(),
      end: moment(data.date).add(1, "minutes").toDate(),
      title: (
        <img
          className="calendarimg"
          src={getImage(data.emotion)}
          alt="...loading"
        ></img>
      ),
    };
  });

  function changeBigFiveWellnessFilters() {
    var filteredData = [];
    
    let filterTimeframe = moment.duration(timeframeToDayConversionDict[filterName], "days");
  
    studentData.forEach((data) => {
      const hour = new Date(data.date).getHours();
      const timeOfDay = getTimeOfDay(hour);
      const timeSlot = getTimeSlot(hour);
  
      const isWithinTimeframe = new Date(data.date).getTime() >= moment().subtract(filterTimeframe).valueOf();
  
      const isTimeSelected = selectedTimes["All Day"] || selectedTimes[timeOfDay];
      const isSubOptionSelected =
        selectedSubOptions[timeOfDay] && selectedSubOptions[timeOfDay][timeSlot];
  
      const isHelpfulnessMatched =
        helpfulFilter === "All" ||
        (helpfulFilter === "Helpful" && data.helpful) ||
        (helpfulFilter === "Not helpful" && !data.helpful);
  
      if (
        (isTimeSelected && !selectedSubOptions[timeOfDay]) ||
        isSubOptionSelected
      ) {
        if (isHelpfulnessMatched && isWithinTimeframe) {
          filteredData.push(data);
        }
      }
    });
    setBigFiveFilteredData(filteredData);
  }
  

  function getTimeOfDay(hour) {
    if (hour >= 0 && hour < 8) return "Early Morning";
    if (hour >= 8 && hour < 12) return "Morning";
    if (hour >= 12 && hour < 18) return "Afternoon";
    return "Evening";
  }

  function getTimeSlot(hour) {
    return `${hour % 12 || 12}${hour < 12 ? "am" : "pm"}-${
      (hour + 1) % 12 || 12
    }${hour < 11 ? "am" : "pm"}`;
  }

  async function helpful(data) {
    data.helpful = !data.helpful;
    try {
      // Call the GraphQL mutation to update the 'helpful' field
      const resp = await updateWellnessHelpfulMutation(
        data._id,
        data.helpful,
        realmUser
      );
      if (resp && resp.updateOneWellness && resp.updateOneWellness._id) {
        navigate("/profilescreen");
      } else {
        console.error("Update failed!");
      }
    } catch (error) {
      console.error("Failed!", error);
    }
    fetchAllData(props.user.user, realmUser);
    setStudentData(studentData);
  }

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const Cal = () => (
    <div className="profilecalendar">
      <h2 className="emotions-summary-title">Calendar</h2>{" "}
      <div className="titledescription" style={{ marginLeft: "0px" }}>
        This calendar shows emotions you’ve felt this month
      </div>
      <Calendar
        width={"63vw"}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        className="calendarsizer"
      />
    </div>
  );

  const BarGraphComponent = (props) => {
    return (
      <div>
        <CongratulationsBanner count={tally} time={filterName} />
        <h2 className="emotions-summary-title">My Graphs</h2>{" "}
        <div style={{ display: "flex" }}>
          <DateframeDropdownSelect
            className="filterprofile"
            onChangeFunction={handleDropdownChange}
            value={filterName}
          ></DateframeDropdownSelect>
        </div>
        <div
          style={{
            width: "90%",
            height: "50vh",
            margin: "auto",
            marginBottom: "4vh",
          }}
        >
          <BarGraph barGraphData={barGraphData} CustomTooltip={CustomTooltip} />
        </div>
      </div>
    );
  };

  const MyWellnessSuccessComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">What Helps</h2>{" "}
        <MyWellnessSuccessTab
          selectedEmotion={selectedEmotion}
          setSelectedEmotion={setSelectedEmotion}
          selectedEmotionColor={selectedEmotionColor}
          setSelectedEmotionColor={setSelectedEmotionColor}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
          studentData={whatHelpsData}
          adultActionImages={props.user.user.adultActionImages}
        ></MyWellnessSuccessTab>
      </div>
    );
  };

  const MyNextStepsComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">My Next Steps</h2>{" "}
        <DateframeDropdownSelect
          className="filterprofile"
          onChangeFunction={handleDropdownChange}
          value={filterName}
        ></DateframeDropdownSelect>
        <MyNextSteps
          filteredNextStepsData={filteredNextStepsData}
          adultActionImages={props.user.user.adultActionImages}
          filterName={filterName}
        ></MyNextSteps>
      </div>
    );
  };

  const BigFiveWellnessDataComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">My History </h2>{" "}
        <p className="my-story-header">Learn more about your emotions and what wellness practices 
          help you the most at different times! </p>
        <DateframeDropdownSelect
            className="filterprofile"
            onChangeFunction={handleDropdownChange}
            value={filterName}
        ></DateframeDropdownSelect>
        <BigFiveWellnessDataWrapper
          bigFiveFilteredData={bigFiveFilteredData}
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          selectedSubOptions={selectedSubOptions}
          setSelectedSubOptions={setSelectedSubOptions}
          helpfulFilter={helpfulFilter}
          setHelpfulFilter={setHelpfulFilter}
          wellnessPopupOpen={wellnessPopupOpen}
          setWellnessPopupOpen={setWellnessPopupOpen}
          adultActionImages={props.user.user.adultActionImages}
        ></BigFiveWellnessDataWrapper>
      </div>
    );
  };

  const AchievementPageComponent = (props) => {
    return (
      <div>
        <h2 className="emotions-summary-title">MY ACHIEVEMENTS</h2>{" "}
        <AchievementPage user={props.user.user} />
      </div>
    );
  };

  const handleNavClick = (index) => {
    setCurrentTabNumber(index);
  };

  const handleNext = () => {
    setCurrentTabNumber((currentTabNumber + 1) % components.length);
  };

  const handleBack = () => {
    setCurrentTabNumber(
      (currentTabNumber - 1 + components.length) % components.length
    );
  };

  const location = useLocation();
  const achievementTab = 2;

  useEffect(() => {
    if (location.state?.activeTab === 'achievement') {
      setCurrentTabNumber(achievementTab);
    }
  }, [location.state]);

  useEffect(() => {
    if (currentTabNumber === achievementTab) {
      window.scrollTo(0, 0);
    }
  }, [currentTabNumber]);


  const components = [
    BarGraphComponent,
    MyWellnessSuccessComponent,
    AchievementPageComponent,
    BigFiveWellnessDataComponent,
    Cal,
    MyNextStepsComponent,
  ];
  const ActiveComponent = components[currentTabNumber];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function checkFavorites(emotion) {
    if (state.favourites === false) {
      return true;
    } else {
      return emotion;
    }
  }
  const CustomTooltip = ({ active, payload, label }) => {
    var total = 0;
    barGraphData.forEach((data) => {
      total += data.count;
    });
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`} </p>
          <p className="bluelabel">{`count: ${payload[0].value}`} </p>
          <p className="bluelabel">{`percentage : ${Math.round(
            (payload[0].value / total) * 100
          )}%`}</p>
          <p className="bluelabel">{`total : ${total}`}</p>
        </div>
      );
    }
    return null;
  };

  const numberWithCommas = (x) => {
    // reg ex for inserting a comma every three digits (ex: 1000 --> 1,000)
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  function handleDropdownChange(e) {
    const newFilterName = e.target.value;
    setFilterName(newFilterName);
    gatherWellnessPractices(feeling, newFilterName);
    changeBigFiveWellnessFilters();
  }

  return (
    <div className="outerbackground">
      {loading && <LoadingOverlay></LoadingOverlay>}
      {/* in the middle of making onselect work for this try using onchange */}
      <div className="columndatatrends">
        <NavigationBar
          activeComponent={currentTabNumber}
          onNavClick={handleNavClick}
          navItems={[
            "My Graphs",
            "What Helps",
            "Achievements",
            "History",
            "Calendar",
            "My Next Steps",
          ]}
        />
        <div className="active-component-container">
          <ScreenshotButtonComponent></ScreenshotButtonComponent>
          <ActiveComponent 
          {...props}
          filterName={filterName}
          setFilterName={setFilterName}
          />
        </div>

        <div className="navigation-buttons">
          <button onClick={handleBack}>BACK</button>
          <button onClick={handleNext}>NEXT</button>
        </div>
      </div>
    </div>
  );
};

export default MyStory;
