import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import "./BigFiveWellnessPractice.css";

const BigFiveInfoDisplay = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="big5-container">
      <div className="big5-header">
        <span>
          Learn which emotions your students have felt at different times and what wellness practices help them the most at different times throughout the day.
        </span>
        {!isOpen ? (
          <FaInfoCircle className="info-icon" onClick={toggleOpen} />
        ) : (
          <span className="hide-text" onClick={toggleOpen}>
            hide
          </span>
        )}
      </div>
      {isOpen && (
        <div className="big5-content">
          <p>
            As a talented and hard-working educator, you can use this information to learn about your student’s patterns, improve academic and behavior outcomes, build strong relationships, and more!
          </p>
        </div>
      )}
    </div>
  );
};

export default BigFiveInfoDisplay;
