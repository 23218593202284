import React, {useState} from 'react'

export const Context2 = React.createContext();

const Audio = ({children}) => {
    const [playing,setPlaying] = useState(false);

    return(
        <Context2.Provider value={[playing,setPlaying]}>{children}</Context2.Provider>
    );
};

export default Audio;