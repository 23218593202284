import "./SeeAndHear2.css";
import BlueSidebar from "../../components/BlueSidebar";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const SeeAndHear2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/deepbreathe3");
    }, 500000);
    return () => clearTimeout(timer);
  }, []);

  return (
    // <AnimatedPage>

    <motion.div class="outer2" initial={{opacity:0.1}} animate={{opacity:1, transition:{delay:1,duration:1}}} exit={{opacity:0}} >
      {/* <BlueSidebar></BlueSidebar> */}
        <div class="inner2">
          <img
            class="seeandhear2"
            src="../images/seeandhear2.png"
            alt="loading..."
          />
        </div>
    </motion.div>
    // </AnimatedPage>

  );
};

export default SeeAndHear2;
