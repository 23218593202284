import "./Actions.css";
import BlueSidebar from "../../components/BlueSidebar";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { useContext, useState } from "react";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";

import { UserContext } from "../../realm/user.context";
import { updateProfile, insertWellness, updateEmotionCount } from "../../realm/graphqlQueries";

const initialState = {
  action: "",
  emotion: "",
  date: "",
  helpful: false,
};

const Actions = (props) => {
  const { realmUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [playing, setPlaying]= useContext(Context2);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  async function createDoc(realmUser, wellnessDataPoint) {
    // Adding the wellness document
    wellnessDataPoint.date = new Date(wellnessDataPoint.date)
    const response = await insertWellness(wellnessDataPoint, props.user.user, realmUser);
    props.lSetWellnessId(response.insertOneWellness._id)
  }

  async function saveToDatabaseAndNav(action) {
    const temp = wellnessDataPoint;
    temp.action = action;
    temp.date = Date.now();
    setWellnessDataPoint(temp);
    createDoc(realmUser, wellnessDataPoint);
    navigate('/performaction')
  }

  var imageSettingFilenameModifier = null
  if (props.user.user.adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }

  const GreenActions = () => {
    const [otherText, setOtherText] = useState('');
    const [visible, setVisible] = useState(false);
    const handleChange = event => {
      setOtherText(event.target.value);
    };

    return(
    <div className="greenrow">
      <img
        onClick={() => saveToDatabaseAndNav("Move my body")}
        className="actions"
        src={`../images/actions/Move my body ${imageSettingFilenameModifier}.png`}
        // src="../images/actions/Move my body.png"
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Smile big")}
        className="actions"
        src={`../images/actions/Smile big ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Stretch")}
        className="actions"
        src={`../images/actions/Stretch ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Be mindful. Be where my feet are")}
        className="actions"
        src={`../images/actions/Be mindful. Be where my feet are ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Help Others")}
        className="actions"
        src={`../images/actions/Help Others ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() =>
          saveToDatabaseAndNav("Think of 3 things that I am grateful for")
        }
        className="actions"
        loading="lazy"
        src={`../images/actions/Think of 3 things that I am grateful for ${imageSettingFilenameModifier}.png`}
        alt="loading..."
      />
      <img
        onClick={() => saveToDatabaseAndNav("Give someone a compliment")}
        className="actions"
        src={`../images/actions/Give someone a compliment ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Give someone a high five")}
        className="actions"
        src={`../images/actions/Give someone a high five ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Write in my journal")}
        className="actions"
        src={`../images/actions/Write in my journal ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Create art")}
        className="actions"
        src={`../images/actions/Create art ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      {visible === true ? (
        <div className="imgTextBoxRow">
          <img
            onClick={() => setVisible(false)}
            class="actions"
            src="../images/actions/Other_BIG_FONT.png"
            alt="loading..."
            loading="lazy"
          />
          <input
            type="text"
            placeholder="Type your wellness practice"
            value={otherText}
            onChange={handleChange}
            className="othertextbox"
          ></input>
          <button 
                  onClick={() => saveToDatabaseAndNav('Other: ' + otherText)}
          className="submitaction"
          style={{marginBottom:'18vh'}}

          >Submit</button>
        </div>
      ) : (
        <img
          onClick={() => setVisible(true)}
          class="actions"
          src="../images/actions/Other_BIG_FONT.png"
          alt="loading..."
          loading="lazy"
          style={{marginBottom:'18vh'}}
        />
      )}
    </div>
  );
      }
  const BlueActions = () => (
    <div className="bluerow" style={{}}>
      <img
        onClick={() =>
          saveToDatabaseAndNav("Imagine positive things in the future")
        }
        className="actions"
        src={`../images/actions/Imagine positive things in the future ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Laugh at something funny")}
        className="actions"
        src={`../images/actions/Laugh at something funny ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() =>
          saveToDatabaseAndNav("Remember that difficult times pass")
        }
        className="actions"
        src={`../images/actions/Remember that difficult times pass ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() =>
          saveToDatabaseAndNav("Remind myself of two good things in my life")
        }
        className="actions"
        src={`../images/actions/Remind myself of two good things in my life ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Tell myself something nice")}
        className="actions"
        src={`../images/actions/Tell myself something nice ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Get some sun in my eyes")}
        className="actions"
        src={`../images/actions/Get some sun in my eyes ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
    </div>
  );
  const PurpleActions = () => (
    <div className="purplerow" style={{}}>
      <img
        onClick={() =>
          saveToDatabaseAndNav("Count to ten taking a deep breath each time")
        }
        className="actions"
        src={`../images/actions/Count to ten taking a deep breath each time ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Imagine a peaceful place")}
        className="actions"
        src={`../images/actions/Imagine a peaceful place ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Listen to music or read")}
        className="actions"
        src={`../images/actions/Listen to music or read ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() =>
          saveToDatabaseAndNav("Politely move away from a distraction")
        }
        className="actions"
        src={`../images/actions/Politely move away from a distraction ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Squeeze my fist")}
        className="actions"
        src={`../images/actions/Squeeze my fist ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
      <img
        onClick={() => saveToDatabaseAndNav("Use my growth mindset")}
        className="actions"
        src={`../images/actions/Use my growth mindset ${imageSettingFilenameModifier}.png`}
        alt="loading..."
        loading="lazy"
      />
    </div>
  );
  function getColor() {
    const temp = wellnessDataPoint;
    const emotion = temp.emotion;
    if (emotion === "happy" || emotion === "grateful" || emotion === "calm")
      // return "#A5E2C6";
      return "green";
    else if (
      emotion === "stressed" ||
      emotion === "scared" ||
      emotion === "angry"
    )
      return "purple";
    // return "#DEC8F1";
    else if (emotion === "sad" || emotion === "tired" || emotion === "lonely")
      // return "#ADCDEA";
      return "blue";
  }

  return (
    <motion.div
      className="outeractions"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <BlueSidebar></BlueSidebar> */}
      <div className='reactplayer'>
        <ReactPlayer playing={playing} url={"audio/"+audio_url_prefix+"/actions.wav"} />
      </div>
      <div className="classandactions">
      <div className="seeandheartextrow">
          <img
            className="greennumber"
            src="../images/green3.png"
            alt="loading..."
            loading="lazy"
          />
          <h1 className="howdoifeel">&nbsp;Is there anything I NEED?</h1>
        </div>
        {getColor() == "green" && <GreenActions></GreenActions>}
        {getColor() == "purple" && (
          <div className="emotionscolumn">
            <PurpleActions />
            <GreenActions />
          </div>
        )}
        {getColor() == "blue" && (
          <div className="emotionscolumn">
            <BlueActions />
            <GreenActions />
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Actions;
