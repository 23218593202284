// handlers/emotionHandler2.js

import { createDataPointProcessor } from "../utils/dataPointProcessor";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import {
  processAllDistricts,
  processAllSchoolsInDistrict,
  processSpecificSchool,
} from "../utils/processSchools";
import {
  processSpecificClass,
  processSpecificStudent,
} from "../utils/processClasses";
import moment from "moment";

export function handleEmotion(state, emotion) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let updateState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );

  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    emotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );

  if (state.chosenDistrict === "All Districts") {
    processAllDistricts(state,processDataPoint)
  } else if (state.chosenSchool === "All Schools") {
    processAllSchoolsInDistrict(state, processDataPoint);
  } else if (state.chosenClass === "All Classes") {
    processSpecificSchool(state, state.chosenSchool, processDataPoint);
  } else if (state.chosenStudent === "All Students") {
    processSpecificClass(state, state.chosenClass, processDataPoint);
  } else {
    processSpecificStudent(state, state.chosenStudent, processDataPoint);
  }

  updateState.filteredDataPoints = filterDataPoints;
  updateState.chosenEmotion = emotion;
  // updateState.tieredSupportSelectedEmotion = emotion;

  
  const emotionColorMapping = {
    sad: "#C9E2FB",
    tired: "#C9E2FB",
    lonely: "#C9E2FB",
    happy: "#C8FADD",
    grateful: "#C8FADD",
    calm: "#C8FADD",
    stressed: "#D2BCE5",
    scared: "#D2BCE5",
    angry: "#D2BCE5",
    "all emotions": "#C8FADD",
  };

  updateState.chosenColor = emotionColorMapping[emotion];
  // updateState.tieredSupportSelectedColor = emotionColorMapping[emotion];
    //  these should not impact this data so dont change it
  // updateState.bigFiveFilteredData = bigFiveFilteredData;

  transform(currentDict, compareDict, updateState);
  transformActions(impactfulActions, updateState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,updateState)

  return updateState;
}
