import React, { useEffect, useRef } from 'react';
import './NavigationBar.css';

const NavigationBar = ({ activeComponent, onNavClick, navItems }) => {
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      const activeButton = navRef.current.children[activeComponent];
      if (activeButton) {
        activeButton.scrollIntoView();
      }
    }
  }, [activeComponent]);

  return (
    <nav className="navigation-bar" ref={navRef}>
      {navItems.map((item, index) => (
        <button
          key={index}
          onClick={() => onNavClick(index)}
          className={activeComponent === index ? 'nav-button active' : 'nav-button'}
        >
          {item}
        </button>
      ))}
    </nav>
  );
};

export default NavigationBar;
