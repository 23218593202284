import { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { authentication } from "../firebase";

export default function EmailLogin({ hideLogin, setAuthenticating }) {
  const [email, setEmail] = useState("");
  const [isErr, handleErr] = useState(false);
  const [password, setPassword] = useState("");
  const [emailErr, handleEmailErr] = useState(false);
  const [emailSuccess, handleEmailSuccess] = useState(false);

  const signUserIn = (email, password) => {
    setAuthenticating(true);
    signInWithEmailAndPassword(authentication, email, password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user;
        // console.log("Logged in user", user.email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log(errorCode, ": ", errorMessage);
        if (errorCode === "auth/invalid-email") {
          handleEmailErr(true);
        } else if (errorCode === "auth/wrong-password") {
          handleErr(true);
        } else {
          createUser(email, password);
        }
      });
    setAuthenticating(false);
  };

  function createUser(email, password) {
    createUserWithEmailAndPassword(authentication, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log(errorMessage);
        if (errorCode === "auth/invalid-email") {
          handleEmailErr(true);
        } else {
          handleErr(true);
        }
        // ..
      });
  }

  function passwordReset(email) {
    sendPasswordResetEmail(authentication, email)
      .then(function () {
        // console.log("email sent");
        handleEmailSuccess(true);
      })
      .catch(function () {
        // console.log("failed to send email");
      });
  }

  return (
    <div id="outerEmailBackground">
      {isErr && (
        <div style={{ color: "#EA4335", marginBottom: "10px" }}>
          The email or password you entered was incorrect
        </div>
      )}
      <label className="labelText">
        Email
      </label>
      <input
        style={{ marginBottom: "10px" }}
        className="emailLoginInputs"
        type="email"
        placeholder="Email..."
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        required
      />
      {emailErr && (
        <div style={{ color: "#EA4335", marginTop: "-10px", marginBottom: "10px" }}>
          Please input a valid email
        </div>
      )}
      <label className="labelText">
        Password
      </label>
      <input
        style={{ marginBottom: "20px" }}
        className="emailLoginInputs"
        type="password"
        placeholder="Password...."
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      {/* <p style={{ fontSize: "10pt", marginTop: "-20px" }}>
        First time logging in? Set your password now.
      </p> */}
      <div
        // style={{ width: "95%", display: "flex", justifyContent: "flex-start" }}
      >
        {/* <button
          id="cancelButton"
          onClick={() => {
            hideLogin(false);
            handleErr(false);
            handleEmailErr(false);
            handleEmailSuccess(false);
          }}
        >
          Cancel
        </button> */}
        <button id="signInButton" onClick={() => signUserIn(email, password)}>
          LOG IN
        </button>
      </div>
      {/* <div
        id="forgotPass"
        style={{
          textDecoration: "underline",
          color: "blue",
          marginTop: "10px",
        }}
        onClick={() => passwordReset(email)}
      >
        Forgot password?
      </div>
      {emailSuccess && (
        <div>Reset Instructions sent to email entered above.</div>
      )} */}
    </div>
  );
}
