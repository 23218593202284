export default function ButtonComponent(props) {
    const src = props.image;
    const txt = props.txt;
    return (
        <div className="button" id="loginButton">
            <img src={src} alt={`${txt} logo`} loading="lazy"/>
            <span className="button-text">{txt}</span>
        </div>
    );
}
