import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import img1 from "../../src/assets/images/kids.png"
import img2 from "../../src/assets/images/youngAdults.png"
import img3 from "../../src/assets/images/adults.png"
import '../../src/assets/css/CarouselPage.css'

function CarouselPage() {
  return (
    <div>
    <Carousel>
    <Carousel.Item>
      <div className="carousel-overlay">
        <img 
          className="carousel-image"
          src={img1}
          alt="First slide"
        />
        <img
          className="overlay"
          src="../images/Rectangle.png"
          alt="Overlay"
        />
      </div>
        
      <Carousel.Caption>
        <div className="card text-start">
            <div className="box">
                <img className="vector" alt="quote" src="../images/Vector.png" />
            </div>
            <p className="quote-text">I love 1-2-3 Wellness! It helps me to learn and build wellness.</p>
            <p className="user"><strong>Michael</strong></p>
            <p className="user-class"><small>3rd Grade</small></p>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <div className="carousel-overlay">
        <img 
          className="carousel-image"
          src={img2}
          alt="Second slide"
        />
        <img
          className="overlay"
          src="../images/Rectangle.png"
          alt="Overlay"
        />
      </div>
      <Carousel.Caption>
        <div className="card text-start">
            <div className="box">
                <img className="vector" alt="quote" src="../images/Vector.png" />
            </div>
            <p className="quote-text">1-2-3 Wellness has helped me manage my anxiety and it really works. My friends and I are learning healthy ways to work with our emotions and new ways to spread kindness.</p>
            <p className="user"><strong>Sienna</strong></p>
            <p className="user-class"><small>10th Grade</small></p>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
    <div className="carousel-overlay">
        <img 
          className="carousel-image"
          src={img3}
          alt="Third slide"
        />
        <img
          className="overlay"
          src="../images/Rectangle.png"
          alt="Overlay"
        />
      </div>
      <Carousel.Caption>
        <div className="card text-start">
            <div className="box">
                <img className="vector" alt="quote" src="../images/Vector.png" />
            </div>
            <p className="quote-text">Our behavior, attendance and academic data have all improved because of 1-2-3 Wellness. My team and I love how simple and powerful the program is for all students and staff. Every school would benefit from 1-2-3 Wellness.</p>
            <p className="user"><strong>Dr. McCarty</strong></p>
            <p className="user-class"><small>Principal</small></p>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  </div>
  )
}

export default CarouselPage