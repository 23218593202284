import React, { useState } from "react";
import "./MyWellnessSuccessTab.css";
import WellnessPracticeHelpfulnessDisplay from "./WellnessPracticeHelpfulnessDisplay";
import TimeframeGrid from "./TimeframeGrid";
import EmotionsSelector from "./EmotionSelector";
import CircleNumber from "./CircleNumber";

const MyWellnessSuccessTab = ({
  selectedEmotion,
  setSelectedEmotion,
  selectedEmotionColor,
  setSelectedEmotionColor,
  selectedTimeframe,
  setSelectedTimeframe,
  studentData,
  adultActionImages,
}) => {
  const EmotionHelpfulnessBanner = ({ selectedEmotion, timeframe }) => {
    var timeframePhraseMapping = {
      "2 hours": "last 2 hours",
      "24 hours": "last 24 hours",
      week: "last week",
      month: " last month",
      "6 months": "last 6 months",
      year: "last year",
    };
    let timePhrase = timeframePhraseMapping[timeframe];

    var emotionPhrase =
      selectedEmotion === "all" ? "all emotions" : selectedEmotion;

    return (
      <div style={{ textAlign: "center" }}>
        <p className="wellness-success-subheader">
          When I have felt <strong>{emotionPhrase}</strong> in the <strong>{timePhrase},</strong> this helped:
        </p>
      </div>
    );
  };

  return (
    <div>
      <EmotionHelpfulnessBanner
        selectedEmotion={selectedEmotion}
        timeframe={selectedTimeframe}
      ></EmotionHelpfulnessBanner>
      <div className="divider"></div>
      <div className="circle-number-container">
        <CircleNumber number={1}></CircleNumber>
        <CircleNumber number={2}></CircleNumber>
        <CircleNumber number={3}></CircleNumber>
      </div>
      <div className="wellnessSuccessBody">
        <EmotionsSelector
          studentData={studentData}
          selectedEmotion={selectedEmotion}
          setSelectedEmotion={setSelectedEmotion}
          setSelectedEmotionColor={setSelectedEmotionColor}
        ></EmotionsSelector>
        <TimeframeGrid
          studentData={studentData}
          selectedTimeframe={selectedTimeframe}
          setSelectedTimeframe={setSelectedTimeframe}
          selectedEmotionColor={selectedEmotionColor}
        ></TimeframeGrid>
        <WellnessPracticeHelpfulnessDisplay
          studentData={studentData}
          selectedTimeframe={selectedTimeframe}
          selectedEmotion={selectedEmotion}
          selectedEmotionColor={selectedEmotionColor}
          adultActionImages={adultActionImages}
        ></WellnessPracticeHelpfulnessDisplay>
      </div>
    </div>
  );
};

export default MyWellnessSuccessTab;
