import React, { useState, useMemo } from "react";
import moment from "moment";
import { NoData } from "../screens/StudentData/components/AlertComponents";

const HelpfulnessItemsDislay = ({ filteredNextStepsData, topActions, adultActionImages }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const practicesArray = useMemo(() => {
    if (topActions && Object.keys(topActions).length > 0) {
      return Object.entries(topActions);
    } else if (filteredNextStepsData && filteredNextStepsData.length > 0) {
      const practiceHelpfulnessCounts = {};

      filteredNextStepsData.forEach((dataPoint) => {
        if (dataPoint.helpful === true) {
          practiceHelpfulnessCounts[dataPoint.action] = (practiceHelpfulnessCounts[dataPoint.action] || 0) + 1;
        }
      });

      return Object.entries(practiceHelpfulnessCounts).sort(([, a], [, b]) => b - a);
    }
    return [];
  }, [filteredNextStepsData, topActions]);

  const practicesDisplayLength = Math.min(practicesArray.length, 3);

  if (practicesArray.length === 0) {
    return <NoData></NoData>;
  }

  var imageSettingFilenameModifier = null
  if (adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }

  return (
    <div className="practice-images-container-row-format">
      {practicesArray.slice(0, practicesDisplayLength).map(([action, count]) => {
        const isOther = action.toLowerCase().startsWith("other");
        const imageSrc = isOther
          ? "../images/actions/Other_BIG_FONT.png"
          : `../images/actions/${action} ${imageSettingFilenameModifier}.png`;

        return (
          <div
            className="next-steps-practice-item"
            key={action}
            onMouseEnter={() => setHoveredItem(action)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <div className="practice-image-container">
              <img src={imageSrc} alt={action} className="practice-image-next-steps" />
              {hoveredItem === action && (
                <div className="hover-text">
                  {action}
                </div>
              )}
            </div>
            <p>
              {count} {count > 1 ? "times" : "time"}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default HelpfulnessItemsDislay;