import { FaInfoCircle } from "react-icons/fa";
import "./CommonStyles.css";

export const NoData = () => {
  return (
    <div className="emotions-log-display message-container">
      <FaInfoCircle color={"#C9E2FB"} className="message-icon" />
      <p className="message-text">No data matches this query</p>
    </div>
  );
};

export const SelectSearchData = ({ screenType }) => {
  return (
    <div className="wellness-success">
      <div className="select-search-data">
        <FaInfoCircle color={"#C9E2FB"} className="message-icon" />
        <p className="message-text">
          Select search data to view {screenType} data
        </p>
      </div>
    </div>
  );
};

export const ChooseAnEmotion = () => (
  <div className="emotions-log-display message-container">
    <FaInfoCircle color={"#C9E2FB"} className="message-icon" />
    <p className="message-text">Choose an emotion to see student data</p>
  </div>
);


export const NoPracticesFound = () => {
  return (
    <div className="message-container">
      <FaInfoCircle color={"#C9E2FB"} className="message-icon" />
      <p className="message-text">No practices found</p>
    </div>
  );
};
