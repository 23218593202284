import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import {
  processAllSchoolsInDistrict,
  processSpecificSchool,
} from "../utils/processSchools";

export function handleSchool(state, school) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let newState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  let bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();
  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );
  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );

  if (school === "All Schools") {
    processAllSchoolsInDistrict(state, processDataPoint);
  } else if (state.districts[state.chosenDistrict][school]) {
    processSpecificSchool(state, school, processDataPoint);
  }

  newState.filteredDataPoints = filterDataPoints;
  newState.chosenSchool = school;
  newState.chosenClass = "All Classes";
  newState.chosenStudent = "All Students";
  newState.tooltipCounts = tooltipCounts;
  newState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))


  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    state.chosenDistrict,
    state,
    emotionDict,
    newState
  );

  transform(currentDict, compareDict, newState);
  transformActions(impactfulActions, newState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,newState)
  return newState;
}

// function processSpecificSchool(state, school, processDataPoint) {
//   let addedStudents = new Set();
//   Object.values(state.districts[state.chosenDistrict][school]).forEach(
//     (className) => {
//       Object.values(className).forEach((student) => {
//         if (state.studentData[student] && !addedStudents.has(student)) {
//           addedStudents.add(student);
//           state.studentData[student].forEach(processDataPoint);
//         }
//       });
//     }
//   );
// }
