import React from "react";
import "./FilterRow.css";
import TimeDropdown from "./Dropdowns/TimeDropdown";
import TimeCompareDropdown from "./Dropdowns/TimeCompareDropdown";
import CombinedSearchDropdown from "./Dropdowns/CombinedSearchDropdown";
import ExpandableSearchDropdown from "./Dropdowns/ExpandableSearchDropdown";

const FilterRow = ({ state, userRole, updateState, activeComponent }) => {
  return (
    <div className="filter-container">
      <div className="filter-row">
        <div className="filter-group filter-group-who">
          <div className="filter-group-label"><strong>Who</strong> (Search for students and/or classes)</div>
          <div className="filter-dropdowns">
            {/* <CombinedSearchDropdown
              state={state}
              userRole={userRole}
              changeDistrictFilter={changeDistrictFilter}
              changeSchoolFilter={changeSchoolFilter}
              changeClassFilter={changeClassFilter}
              changeStudentFilter={changeStudentFilter}
            /> */}
            <ExpandableSearchDropdown
              state={state}
              userRole={userRole}
              updateState={updateState}
              // changeDistrictFilter={changeDistrictFilter}
              // changeSchoolFilter={changeSchoolFilter}
              // changeClassFilter={changeClassFilter}
              // changeStudentFilter={changeStudentFilter}
            />
          </div>
        </div>
        <div className="filter-group filter-group-when">
            {activeComponent !== 0 && (
              <>
              <div className="filter-group-label">
                <strong>When</strong>
              </div>
              <div className="filter-dropdowns">
                <TimeDropdown state={state} updateState={updateState} />
              </div>
              </>
            )}            
            {activeComponent === 0 && (
              <>
                <div className="filter-group-labels">
                  <div className="filter-group-label"><strong>When</strong></div>
                  <div className="filter-group-label"><strong>Compare to</strong></div>
                </div>
                <div className="filter-dropdowns">
                  <TimeDropdown state={state} updateState={updateState} />
                  <TimeCompareDropdown state={state} updateState={updateState} />
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default FilterRow;

// import React from "react";
// import "./FilterRow.css";
// import DistrictDropdown from "./Dropdowns/DistrictDropdown";
// import SchoolDropdown from "./Dropdowns/SchoolDropdown";
// import TimeDropdown from "./Dropdowns/TimeDropdown";
// import TimeCompareDropdown from "./Dropdowns/TimeCompareDropdown";
// import ClassDropdown from "./Dropdowns/ClassDropdown";
// import StudentDropdown from "./Dropdowns/StudentDropdown";

// const FilterRow = ({
//   state,
//   changeDistrictFilter,
//   changeSchoolFilter,
//   changeTimeFilter,
//   changeTimeFilterCompare,
//   changeClassFilter,
//   changeStudentFilter,
//   userRole,
// }) => {
//   const renderWhoFilters = () => {
//     switch (userRole) {
//       case "123wellness":
//         return (
//           <>
//             <DistrictDropdown
//               state={state}
//               changeDistrictFilter={changeDistrictFilter}
//             />
//             <SchoolDropdown
//               state={state}
//               changeSchoolFilter={changeSchoolFilter}
//             />
//           </>
//         );
//       case "superadmin":
//         return (
//           <>
//             <SchoolDropdown
//               state={state}
//               changeSchoolFilter={changeSchoolFilter}
//             />
//             <ClassDropdown
//               state={state}
//               changeClassFilter={changeClassFilter}
//             />
//           </>
//         );
//       case "admin":
//         return (
//           <>
//             <ClassDropdown
//               state={state}
//               changeClassFilter={changeClassFilter}
//             />
//             <StudentDropdown
//               state={state}
//               changeStudentFilter={changeStudentFilter}
//             />
//           </>
//         );
//       case "teacher":
//         return (
//           <>
//             <ClassDropdown
//               state={state}
//               changeClassFilter={changeClassFilter}
//               creatorClasses={state.userCreatedClasses} // Assuming this is where we store the classes created by the teacher
//             />
//             <StudentDropdown
//               state={state}
//               changeStudentFilter={changeStudentFilter}
//             />
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="filter-container">
//       <div className="filter-row">
//         <div className="filter-group">
//           <div className="filter-group-label">Who</div>
//           <div className="filter-dropdowns">{renderWhoFilters()}</div>
//         </div>
//         <div className="filter-group">
//           <div className="filter-group-label">When</div>
//           <div className="filter-dropdowns">
//             <TimeDropdown state={state} changeTimeFilter={changeTimeFilter} />
//             <TimeCompareDropdown
//               state={state}
//               changeTimeFilterCompare={changeTimeFilterCompare}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FilterRow;
