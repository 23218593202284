import "./DeepBreathe.css";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";

const DeepBreathe3 = (props) => {
  const navigate = useNavigate();
  const [playing, setPlaying] = useContext(Context2);
  const audio_url_prefix = props.user.user.voice ? props.user.user.voice.replace(" ", "_") : "english_female";

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/actions");
    }, 8500);
    return () => clearTimeout(timer);
  }, []);
  // const handleAudioEnd = () => {
  //   navigate("/actions");
  // };

  return (
    <motion.div className="outerbreath1">
      <div className="reactplayer">
        <ReactPlayer
          playing={playing}
          url={"audio/" + audio_url_prefix + "/deepbreathe3.wav"}
          // onEnded={handleAudioEnd}
        />
      </div>
      <div className="outercolumnbreathe">
        <div className="text">Please take a deep breath</div>
        <img
          className="gif"
          src="../images/GIF_ThirdBreath_Crop.gif"
          alt="loading..."
          loading="lazy"
        />
      </div>
    </motion.div>
  );
};

export default DeepBreathe3;
