import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { useState, useContext, useEffect} from "react";
import _ from "lodash";
import { deleteClasses, deleteDistrict, deleteSchools, insertDistrict, insertProfiles, loadDistricts, loadProfiles, updateDistrict, updateProfile, updateProfiles} from "../../../realm/graphqlQueries";
import { UserContext } from "../../../realm/user.context";
import { CircularProgress } from "@mui/material";

const WellnessAdministratorScreen = () => {
  const rowcount = 10;
  let grid = [
    [
      { readOnly: true, value: "", width: "20vw" },
      { value: "Admin Emails", width: "30vw", readOnly: true },
      // { value: "Class", width: "20vw", readOnly: true },
    ],
  ].concat(
    _.range(1, rowcount + 1).map((id) => [
      { readOnly: true, value: `Admin ${id}` },
      { readOnly: true, value: "" },
      // { value: "" },
    ])
  );
  const {realmUser} = useContext(UserContext)
  const [chosenDistrict, setChosenDistrict] = useState("");
  const [newDistrictName, setNewDistrictName] = useState("");
  const [removeDistrictName, setRemoveDistrictName] = useState("");
  const [state, setState] = useState({ grid });
  const [districtObject, setDistrictObject] = useState("");
  const [chosenDistrictIndex, setChosenDistrictIndex] = useState(-1)
  const [districtList, setDistrictList] = useState([]);
  const [loading, setLoading] = useState(true);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  async function addNewDistrictToDB() {
    if(newDistrictName===""){
      alert("District Name cannot be empty")
      return
    }
    if(districtList.includes(newDistrictName)){
      alert("There already exists a district with this name")
      return
    }
    let districts = districtList
    districts.push(newDistrictName)
    const districtData = {name:newDistrictName,superadmins:[],schools:[]}
    const districtRef = await insertDistrict(districtData,realmUser)
    console.log(districtRef + " added to DB")
    let tmpDistrictObject = districtObject
    tmpDistrictObject.districts.push(districtData)
    setDistrictObject(tmpDistrictObject)
    setDistrictList(districts)
    setNewDistrictName("");
  }
  async function removeDistrictFromDB() {
    setChosenDistrict("Choose district")
    // remove the entire thing from db or first check it exists in collection and then delete
    let tmpDistrictObject = districtObject
    let districtDex = -1
    for(let i =0;i<tmpDistrictObject.districts.length;i++){
      if(tmpDistrictObject.districts[i].name===removeDistrictName){
        districtDex=i
      }
    }
    if(districtDex===-1){
      alert("Chosen district is not in local object")
    }
    //DeleteSchools, Classes and associated users.
    const deletedSchoolsRes = await deleteSchools({district:removeDistrictName}, realmUser)
    const deletedClassesRes = await deleteClasses({district:removeDistrictName}, realmUser)
    const changedProfilesRes = await updateProfiles({district:removeDistrictName},realmUser,{district:"",class:[],school:""})
    const deletedDistrictRes = await deleteDistrict(removeDistrictName,realmUser)
    
    console.log("Deleted District", deletedDistrictRes, changedProfilesRes, deletedClassesRes, deletedSchoolsRes)
    tmpDistrictObject.districts.splice(districtDex,1)
    let tmpDistrictList = tmpDistrictObject.districts.map(x=>x.name)
    updateGrid("Choose district",-1)
    setDistrictList(tmpDistrictList)
    setDistrictObject(tmpDistrictObject)
  }
  async function addFromSheet(laddedAdmins){
    let addedAdmins = laddedAdmins.filter(x=>isValidEmail(x)).map(x=>x.toLowerCase())
    //Update profiles
    const profileReq = await loadProfiles(addedAdmins,realmUser)
    let oldUsers = profileReq.users.map(user=>user.email)
    oldUsers.forEach(email=>{
      const updateData = {
        user:"superadmin",
        dataUse:false,
        district:chosenDistrict,
        school: "",
        class: []
      }
      updateProfile(email,realmUser,updateData)
    })
    let userData = []
    let newUsers = addedAdmins.filter(x=>!oldUsers.includes(x))
    newUsers.forEach(email=>{
      userData.push({
        email:email,
        district:chosenDistrict,
        school:"",
        class:[],
        agreedToTerms: false,
        dataUse:true,
        user:"superadmin",
        count:0,
        wellnesstimer:"30 seconds"
      })
    })
    if(userData.length!==0){
      console.log(userData)
      await insertProfiles(userData,realmUser)
    }
    let tmpDistrictObject = districtObject
    //Make sure admins are unique and valid. 
    let allAdmins = [...new Set(tmpDistrictObject.districts[chosenDistrictIndex].superadmins.concat(addedAdmins))]
    if(allAdmins.length!==0){
      const updateDistrictRef = await updateDistrict(chosenDistrict,realmUser,{superadmins:allAdmins})
      console.log("Updated District and added Profiles", addedAdmins, updateDistrictRef)
    }
    tmpDistrictObject.districts[chosenDistrictIndex].superadmins = allAdmins
    setDistrictObject(tmpDistrictObject)
    updateGrid(chosenDistrict,chosenDistrictIndex)

  }
  async function deleteFromSheet(ldeletedAdmins){
    let deletedAdmins = ldeletedAdmins.filter(x=>isValidEmail(x)).map(x=>x.toLowerCase())
    deletedAdmins.forEach(email=>{
      updateProfile(email,realmUser,{district:"",school:""})
    })
    //update school
    let remainingAdmins = districtObject.districts[chosenDistrictIndex].superadmins.filter(x=>!deletedAdmins.includes(x))
    updateDistrict(chosenDistrict,realmUser,{"superadmins":remainingAdmins})
    //console.log("deleting admins: ",deletedAdmins,"remaining:",, updateDistrictRef)
    //update state
    let tmpDistrictObject = districtObject
    districtObject.districts[chosenDistrictIndex].superadmins=remainingAdmins
    setDistrictObject(tmpDistrictObject)
    updateGrid(chosenDistrict,chosenDistrictIndex)
  }
  
  function updateGrid(chosendistrict, chosenDex) {
    let grid = [];
    console.log(chosenDex,districtObject,chosendistrict)
    if (chosendistrict === "Choose district") {
      grid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Admin Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Admin ${id + 1}` },
          { readOnly: true, value: "" },
          // { value: "" },
        ])
      );
      setState({ grid });
    } else {
      grid = [
        [
          { readOnly: true, value: "", width: "20vw" },
          { value: "Admin Emails", width: "30vw", readOnly: true },
        ],
      ].concat(
        _.range(0, rowcount).map((id) => [
          { readOnly: true, value: `Admin ${id + 1}` },
          { value: districtObject.districts[chosenDex].superadmins[id] },
          // { value: "" },
        ])
      );
    }
    setState({ grid });
  }
  
  function setChosenDistrictUpdateGrid(chosendistrict) {
    let chosenDex = 0
    for(let i = 0;i<districtObject.districts.length;i++){
      if(districtObject.districts[i].name===chosendistrict){
        chosenDex=i
      }
    }
    if(chosenDex===-1){
      console.error(chosendistrict," not found in ", districtObject)
      return;
    }
    setChosenDistrictIndex(chosenDex)
    updateGrid(chosendistrict, chosenDex);
    setChosenDistrict(chosendistrict,chosenDex);
  }
  async function getData() {
    setLoading(true)
    const districtRef = await loadDistricts(realmUser)
    let tmpDistrictObject = districtRef
    let tmpDistrictList = districtRef.districts.map(x=>x.name)
    setDistrictList(tmpDistrictList)
    setDistrictObject(tmpDistrictObject)
    setLoading(false)
  }
  useEffect(() => {
    getData();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading&&
      <div id="progress" style={{height:"100vh", width:"100vw",position: "fixed", top: "1px",background: "rgba(0,0,0,0.5)", zIndex:"100", display:"flex",justifyContent: "center",
  alignItems: "center"}}>
      <CircularProgress style={{height:"60px",width:"60px"}} />
      </div>}
    <div class="studentscol">
      <div class="schoolsheader">Set Up Your District!</div>
      <div class="editclassesrow">
        <div>
          <div class="addorremoveschool">Add or remove a district</div>
          <div class="inputandbuttonrow">
            <input
              type="text"
              placeholder="Create a district"
              value={newDistrictName}
              onChange={(e) => setNewDistrictName(e.target.value)}
              class="newschooltextbox"
            ></input>
            <button className="addnewclass" onClick={() => addNewDistrictToDB()}>
              Create district
            </button>
            <select
              class="schoolselectdelete"
              onChange={(e) => setRemoveDistrictName(e.target.value)}
            >
              <option value={"Choose district"}>Remove a district</option>
              {districtList.map((districtname) => (
                <option value={districtname}>{districtname}</option>
              ))}
            </select>{" "}
            <button class="addnewclass" onClick={() => removeDistrictFromDB()}>
              Remove a district
            </button>
          </div>
            <div class="selectschoolrow">
              {chosenDistrict === "Choose district" || chosenDistrict === "" ? (
                <div class="chooseaschooltext">
                  Choose a district to add super-admins to
                </div>
              ) : (
                <div class="chooseaschooltext">
                  Adding new super-admin to {chosenDistrict}!
                </div>
              )}
              <select
                class="schoolselect"
                onChange={(e) => setChosenDistrictUpdateGrid(e.target.value)}
              >
                <option value={"Choose district"}>Choose district</option>
                {districtList &&
                  districtList.map((districtname) => (
                    <option value={districtname}>{districtname}</option>
                  ))}
              </select>{" "}
            </div>
        </div>
      </div>
      {/* <div class="spreadsheetrowschools"> */}
      <div class="limitspreadsheetschools">
        <ReactDataSheet
          data={state.grid}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={(changes) => {
            let addedAdmins = []
            let deletedAdmins = []
            const grid = state.grid.map((row) => [...row]);
            changes.forEach(({ cell, row, col, value }) => {
              grid[row][col] = { ...grid[row][col], value };
              if (value === "") {
                deletedAdmins.push(cell.value)
              }
              if (value) {
                addedAdmins.push(value)
              }
            });
            if (addedAdmins.length > 0) {
              addFromSheet(addedAdmins)
            }
            if (deletedAdmins.length > 0) {
              deleteFromSheet(deletedAdmins)
            }
            setState({ grid });
          }}
        />{" "}
      </div>
      {/* <AlertDialog /> */}
      {/* </div> */}
    </div>
    </div>
  );
};
export default WellnessAdministratorScreen;
