import React from "react";
import "./MyNextSteps.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faHeart,
  faTrophy,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import HelpfulnessItemsDislay from "./HelpfulnessItemsDislay";
import EmotionsFeltDisplay from "./EmotionsFeltDisplay";

const MyNextSteps = ({
  filteredNextStepsData,
  impactfulActions,
  state,
  userRole,
  adultActionImages,
  filterName,
}) => {
  const topActions = impactfulActions?.action || {};
  const topEmotions = impactfulActions?.emotion || {};

  const getHeaderTitle = () => {
    if (
      userRole === "123wellness" ||
      userRole === "superadmin" ||
      userRole === "admin" ||
      userRole === "teacher"
    ) {
      return "3 Ways to Continue Empowering Your Students";
    }
    return "3 Ways to Continue Growing";
  };
  const getWellnessPracticesQuestion = () => {
    if (
      userRole === "123wellness" ||
      userRole === "superadmin" ||
      userRole === "admin" ||
      userRole === "teacher"
    ) {
      return "How might you integrate more of these practices to your daily interactions with students? (lesson plans, routines, relationship building opportunities, etc.)";
    }
    return "How can you use wellness practices to help you learn and grow?";
  };
  const getPracticeTitle = () => {
    if (
      userRole === "123wellness" ||
      userRole === "superadmin" ||
      userRole === "admin" ||
      userRole === "teacher"
    ) {
      return "LEARN WHAT ACTIONS HELP YOUR STUDENTS";
    }
    return "LEARN WHAT HELPS YOU LEARN & GROW";
  };
  const getEmotionsTitle = () => {
    if (
      userRole === "123wellness" ||
      userRole === "superadmin" ||
      userRole === "admin" ||
      userRole === "teacher"
    ) {
      return "LEARN HOW YOUR STUDENTS ARE FEELING";
    }
    return "LEARN FROM YOUR EMOTIONS";
  };

  const getWellnessPracticesText = () => {
    if (!state || !userRole) {
      return (
        <>
          These are your top 3 helpful wellness practices in the past <b>{filterName || "period"}</b>.
        </>
      );
    }

    let name =
      state.studentEmailToNameMap && state.chosenStudent
        ? state.studentEmailToNameMap[state.chosenStudent] ||
          state.chosenStudent
        : "the student";

    switch (userRole) {
      case "123wellness":
        return (
          <>
            These are the top wellness practices for <b>{state.chosenSchool || "the school"}</b> in <b>{state.chosenDistrict || "the district"}</b> in the last <b>{state.chosenTime || "period"}</b>.
          </>
        );
      case "superadmin":
        return (
          <>
            These are the top wellness practices for <b>{name}</b> in <b>{state.chosenClass || "the class"}</b> in <b>{state.chosenSchool || "the school"}</b> in the last <b>{state.chosenTime || "period"}</b>.
          </>
        );
      case "admin":
      case "teacher":
        return (
          <>
            These are the top wellness practices for <b>{name}</b> in <b>{state.chosenClass || "the class"}</b> in the last <b>{state.chosenTime || "period"}</b>.
          </>
        );
      default:
        return (
          <>
            These are your top 3 helpful wellness practices in the past <b>{filterName || "period"}</b>.
          </>
        );
    }
  };
  
  return (
    <div className="next-steps-container">
      <div className="three-ways-subheader">
        {getHeaderTitle()}
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faBookOpen} />
        </span>
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faHeart} />
        </span>
        <span className="icon-circle header-icon-circle">
          <FontAwesomeIcon className="header-icon" icon={faTrophy} />
        </span>
      </div>

      
      <div className="quick-tips">
        <div className="icon-column">
          <span className="icon-circle">
            <FontAwesomeIcon className="fa-icon" icon={faLightbulb} />
          </span>
        </div>
        {!state || !userRole ? (
        <span className="tooltip-text">
          <em>
            Remember, 1-2-3 Wellness can help you prepare your heart (emotions) and 
            brain (mind) for success.
            <br />
            <br />
            Just as we stretch before a physical activity like running, you can use
            1-2-3 Wellness just <u>before</u> you want to “prime your brain” 
            to succeed at something mental, emotional, or physical.
            <br />
            <br />
            You are invited to try practicing 1-2-3 Wellness just before you want
            to focus on something important to you (before an
            important conversation, test, performance, or sporting event).
          </em>
        </span>
        ) : (
        <span className="tooltip-text">
          <em>
            Remember, as a talented and hard-working educator, YOUR wellness matters
            too. Continue to practice 1-2-3 Wellness every day with your students.
            You deserve it!
            <br />
            <br />
            Just as we stretch before a physical activity like running, you can use
            1-2-3 Wellness just before you want to “prime your brain” and/or your
            student’s brains to succeed at something mental, emotional, or physical.
            <br />
            <br />
            You are invited to try practicing 1-2-3 Wellness just before you want
            your students to focus on something important to you (before an
            important conversation, test, performance, or sporting event).
            <br />
            <br />
            Remember, small habits add up. By practicing daily with your students,
            you are helping them adopt healthy practices which can support their
            success for a lifetime. Great work!
          </em>
        </span>
        )}
      </div>
      
      <div id="steps">
        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faBookOpen} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">{getPracticeTitle()}</h2>
            <div className="step-content">
            <div className="step-content">
              <p>{getWellnessPracticesText()}</p>
              <p><em>{getWellnessPracticesQuestion()}</em></p>
            </div>
              <HelpfulnessItemsDislay
                filteredNextStepsData={filteredNextStepsData}
                topActions={topActions}
                adultActionImages={adultActionImages}
              />
            </div>
          </div>
        </div>

        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faHeart} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">{getEmotionsTitle()}</h2>
            {!state || !userRole ? (
              <div className="step-content">
                These are the top emotions that you’ve been feeling. 
                It’s OK to feel different emotions. They don’t last forever.
                <p><em>What are you learning about your emotions?</em></p>
              </div>
            ) : (
              <div className="step-content">
                <p>These are the top three emotions your students have been feeling. 
                  As we know, emotions can affect so many aspects of home and school life: academic success, behavior, relationships and more. 
                </p>
                <p><em>How might you continue to build strong relationships and support students to navigate their emotions in healthy ways?</em></p>
                <p className="step-example">
                  (Ex: Having individual or classroom discussions, normalizing that it is OK to feel different emotions, 
                  reminding them that emotions don’t last forever, using the 1-2-3 Wellness practice and/or lesson plans, etc.)
                </p>
              </div>
            )}
            
            <EmotionsFeltDisplay
              filteredNextStepsData={filteredNextStepsData}
              topEmotions={topEmotions}
            />
          </div>
        </div>

        <div className="step-container">
          <div className="icon-column">
            <span className="icon-circle">
              <FontAwesomeIcon className="fa-icon" icon={faTrophy} />
            </span>
          </div>
          <div className="text-column">
            <h2 className="step-header">CELEBRATE & REFLECT</h2>
            {!state || !userRole ? (
              <div className="step-content">
                <p>You are doing great! </p>
                <em>How might you celebrate your wellness success and continue building?</em>
              </div>
            ) : (
            <p className="step-content">
              <p>Being an educators is hard. You are doing an awesome job! </p>
              <em>How might you <u>celebrate</u> and <u>build upon</u> the wellness that you and your students are cultivating?</em>
            </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNextSteps;
