

const EmotionsSelector = ({selectedEmotion, setSelectedEmotion, setSelectedEmotionColor}) => {

    const emotionEmojiImgMapping = [["sad", "images/emotions/sad.png"],
                                    ["tired", "images/emotions/tired.png"], 
                                    ["lonely", "images/emotions/lonely.png"], 
                                    ["happy", "images/emotions/happy.png"], 
                                    ["grateful", "images/emotions/grateful.png"], 
                                    ["calm", "images/emotions/calm.png"], 
                                    ["stressed", "images/emotions/stressed.png"], 
                                    ["scared", "images/emotions/scared.png"], 
                                    ["angry", "images/emotions/angry.png"]]

    const emotionColorMapping = {"sad":'#C9E2FB', "tired":'#C9E2FB', "lonely":'#C9E2FB', 
                                "happy":'#C8FADD', "grateful":'#C8FADD', "calm":'#C8FADD', 
                                "stressed":'#D2BCE5', "scared":'#D2BCE5', "angry":'#D2BCE5',
                                "all":"#C8FADD"}

    const handleImageClick = (alt) => {
        setSelectedEmotion(alt);
        setSelectedEmotionColor(emotionColorMapping[alt]);
    };

    const handleAllEmotionsClick = () => {
        setSelectedEmotion('all');
        setSelectedEmotionColor(emotionColorMapping['all']);
    };
    

    return(
        <div className="image-grid-container">
            <div className={`all-emotions ${selectedEmotion === 'all' ? 'selected' : ''}`} onClick={handleAllEmotionsClick}>
                <img alt="all-emotions" src="images/emotions/all_emotions.png" className={`all-emotions-image ${selectedEmotion === 'all' ? 'selected' : ''}`}></img>
                <p className="all-emotions-label">All Emotions</p>
            </div>
            <div className="image-grid-mystory">
                {emotionEmojiImgMapping.map(([alt, src], index) => (
                    <div key={index} className="emotionEmojiDiv">
                        <img
                            key={index}
                            src={src}
                            alt={alt}
                            className={`grid-image-mystory ${selectedEmotion === alt || selectedEmotion === 'all' ? 'selected' : ''}`}
                            onClick={() => handleImageClick(alt)}
                        />
                        <p className={`emotionEmojiLabel ${selectedEmotion === alt || selectedEmotion === 'all' ? 'selected' : ''}`}>{alt}</p>
                    </div>
                ))}
            </div>
        </div>

    )
}

export default EmotionsSelector