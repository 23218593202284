import React, {useState} from 'react'

export const Context3 = React.createContext();

const Timer = ({children}) => {
    const [timer,setTimer] = useState("10 seconds");

    return(
        <Context3.Provider value={[timer,setTimer]}>{children}</Context3.Provider>
    );
};

export default Timer;