import moment from "moment";

export function updateEmotions(
  feeling,
  time,
  district,
  state,
  emotionDict,
  updateState
) {
  const currentDate = moment().subtract(time);
  const processDataPoint = createEmotionDataPointProcessor(
    currentDate,
    feeling,
    emotionDict
  );

  if (district === "All Districts") {
    state.allWellnessPoints.forEach(processDataPoint);
  } else {
    processStudentsInDistrict(state, district, processDataPoint);
  }

  updateEmotionState(updateState, feeling, emotionDict);
}

function createEmotionDataPointProcessor(currentDate, feeling, emotionDict) {
  return (datapoint) => {
    let datapointTimestamp = new Date(datapoint.date).valueOf();
    if (datapointTimestamp >= currentDate.valueOf() && datapoint.helpful) {
      let action = datapoint.action;
      if (!emotionDict[feeling]) {
        emotionDict[feeling] = {};
      }
      if (!emotionDict[feeling][action]) {
        emotionDict[feeling][action] = 0;
      }
      emotionDict[feeling][action]++;
    }
  };
}

function processStudentsInDistrict(state, district, processDataPoint) {
    state.allStudents.forEach((student) => {
    const studentEmail = student.email;
    if (state.studentData[studentEmail]) {
      state.studentData[studentEmail].forEach(processDataPoint);
    }
  });
}

function updateEmotionState(updateState, feeling, emotionDict) {
  let feelingList = feeling === "select" ? [] : Array.from(Object.keys(emotionDict[feeling] || {}));
  if (feelingList.length > 3) {
    let objectList = Object.keys(emotionDict[feeling] || {}).sort(
      (a, b) => (emotionDict[feeling][b] || 0) - (emotionDict[feeling][a] || 0)
    );
    feelingList = objectList.slice(0, 3);
  }
  updateState.wellnessList = feelingList;
  updateState.feeling = feeling;
}