import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyAMl2P7ekzVYXrA-v_uvLdXmb7s-bRwvd0",
  authDomain: "wellness-a9157.firebaseapp.com",
  projectId: "wellness-a9157",
  storageBucket: "wellness-a9157.appspot.com",
  messagingSenderId: "830130482578",
  appId: "1:830130482578:web:c65840e8fcd403cba9a54b",
  measurementId: "G-32YTP3YDDV"
};
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
export const firestore = getFirestore(app);


// export const Signin = () => {
//   // const {user,setUser} = useContext(UserContext);
//   const provider = new GoogleAuthProvider();
//   signInWithPopup(authentication, provider)
//   .then((result) => {
//       // This gives you a Google Access Token. You can use it to access the Google API.
//       const credential = GoogleAuthProvider.credentialFromResult(result);
//       const token = credential.accessToken;
//       // The signed-in user info.
//       const user = result.user
//       setUser(result.user);
//       console.log(user);
//       console.log(result.user);

//       // ...
//     }).catch((error) => {
//       // Handle Errors here.
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       // The email of the user's account used.
//       const email = error.customData.email;
//       // The AuthCredential type that was used.
//       const credential = GoogleAuthProvider.credentialFromError(error);
//       // ...
//     });
// };

// export async function fetchStudentData() {
//   setLoading(true);
//   const docRef = doc(
//     firestore,
//     "/districts/district1/schools/school1/classes/class1/students",
//     "student1"
//   );
//   const docSnap = await getDoc(docRef);
//   setStudentName(docSnap.data().name);
//   setLoading(false);
// }

// export async function fetchWellnessHistoryData(){
//   const querySnapshot = await getDocs(collection(
//     firestore,
//     "/districts/district1/schools/school1/classes/class1/students/student1/wellness history"
//   ));
//   const studentdata = [];
//   querySnapshot.forEach((doc) => {
//     // doc.data() is never undefined for query doc snapshots
//     studentdata.push(doc.data());
//   });
//   setStudenthistory(studentdata);
// }

export const Signout = () => {
  signOut(authentication)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};
