import {
  signInWithPopup,
  GoogleAuthProvider, 
  OAuthProvider,
} from "firebase/auth";
import { authentication } from "../../firebase";
import EmailLogin from "../../components/EmailLogin";
import "./SigninScreen.css";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import ButtonComponent from "../../components/ButtonComponent";
import GoogleImage from "../../../src/assets/images/googleLogo.png"
import MicrosoftImage from "../../../src/assets/images/microLogo.png"
import CleverImage from "../../../src/assets/images/cleverLogo.png"
import CarouselPage from "../../components/CarouselPage";
import "../../components/components.css"

const cleverClientID = "81c607e331c7da3c5547";
const redirect_uri = window.location.href.split(/#\?/)[0];


const AuthMethods = ({ setShowEmail, setAuthenticating, setLoginFailure }) => {
  async function GoogleAuth() {
    setAuthenticating(true);
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      //   // Force re-consent.
      prompt: "select_account",
    });
    signInWithPopup(authentication, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        GoogleAuthProvider.credentialFromResult(result);
      })
      .catch((error) => {
        // TODO: handle errors
      });
    setAuthenticating(false);
  }

  const MicrosoftAuth = (setLoginFailure) => {
    setAuthenticating(true);
    const microProvider = new OAuthProvider("microsoft.com");
    microProvider.setCustomParameters({
      prompt: "select_account",
    });
    signInWithPopup(authentication, microProvider)
      .then(() => {
        // TODO: do something on success?
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          setLoginFailure(true);
        }
      });
    setAuthenticating(false);
  };

  return (
    <div>
      <button
        className="btn-parent"
        onClick={() => {
          setAuthenticating(true);
          GoogleAuth();
        }}
        // style={{ marginTop: "8vh" }}
      >
        <ButtonComponent image={GoogleImage} txt="Sign In With Google"/>
      </button>
      <button
        className="btn-parent"
        onClick={() => MicrosoftAuth(setLoginFailure)}
      >
        <ButtonComponent image={MicrosoftImage} txt="Sign In Microsoft"/>
      </button>
      <button
        className="btn-parent"
        onClick={() => {
          window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirect_uri}&client_id=${cleverClientID}`;
        }}
      >
        <ButtonComponent image={CleverImage} txt="Sign In With Clever"/>
      </button>
    </div>
  );
};

const AuthIndicator = () => {
  return (
    <div
      id="progress"
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "1px",
        background: "rgba(0,0,0,0.5)",
        zIndex: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress style={{ height: "60px", width: "60px" }} />
    </div>
  );
};

export const Footer = () => {
  return (
    <>
      <div className="bottom-text">
          <p>© GrowthWell LLC 2024</p>
          <p>U.S. Patent No. 12,053,300</p>
          <p class="footer-links">
            <a href="https://drewschwartz.com/privacy-policy" className="bottom-text"
                target="_blank">Privacy Policy
            </a> 
                {/* <div id ="separator_line_box" class="box"> */}
                  <img id="separator_line" class="line" src="../images/line_9.png" />
                {/* </div> */}
            
            <a  href="https://drewschwartz.com/data-governance"
              className="bottom-text " target="_blank">Data Governance
            </a>

              {/* <div id ="separator_line_box" class="box"> */}
              <img id="separator_line" class="line" src="../images/line_9.png" />
                {/* </div> */}

            <a  href="https://drewschwartz.com/terms-of-service"
              className="bottom-text " target="_blank">Terms of Service
            </a>            
          </p>
        </div>
    </>
  );
};

const SigninScreen = ({ cleverAuthCode }) => {
  const [showEmail, setShowEmail] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);

  useEffect(() => {
    if (cleverAuthCode) {
      console.log(true);
    }
  }, [cleverAuthCode]);

  return (
    <div className="auth-container">
      <div className="row">
        <div className="container1">
          <div className="content">
            <div>
              <img className="logo-image-top" alt="logo" src="../images/123WellnessLogo green-02.png" />
            </div>
            <div className="top-text">
              <p className="text-wrapper">Welcome to the 1-2-3 Wellness app!</p>
            </div>
              
              {(authenticating || cleverAuthCode) && <AuthIndicator />}
            
              <AuthMethods
                  setShowEmail={setShowEmail}
                  setLoginFailure={setLoginFailure}
                  setAuthenticating={setAuthenticating}
                />
              <div className="line">
                <p className="text-center login_input_field" id="line_with_or">OR</p>
              </div>

              <EmailLogin
                  hideLogin={setShowEmail}
                  setAuthenticating={setAuthenticating}
                />
              
          </div>
          <div className="bottom-text">
            <Footer />
          </div>
        </div>
        <div className="container2" >
          <CarouselPage/>
        </div>
      </div>
      
      
    </div>
  );
};
export default SigninScreen;
