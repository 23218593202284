export function initializeDictionaries() {
    const emotions = [
      "sad", "tired", "lonely", "happy", "grateful", "calm", "stressed", "scared", "angry",
    ];
    const currentDict = {};
    const compareDict = {};
    emotions.forEach((emotion) => {
      currentDict[emotion] = 0;
      compareDict[emotion] = 0;
    });
    return { currentDict, compareDict };
  }
  
  export function initializeEmotionDict() {
    const emotions = [
      "sad", "tired", "lonely", "happy", "grateful", "calm", "stressed", "scared", "angry",
    ];
    const emotionDict = {};
    emotions.forEach((emotion) => {
      emotionDict[emotion] = {};
    });
    return emotionDict;
  }