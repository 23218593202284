import React, { useState } from "react";
import moment from "moment";
import {
  NoData,
  NoPracticesFound,
} from "../screens/StudentData/components/AlertComponents";
import { timeHelper } from "../screens/StudentData/utils/timeHelper";
import "../components/MyWellnessSuccessTab.css";

const WellnessPracticeHelpfulnessDisplay = ({
  studentData,
  selectedEmotion,
  selectedTimeframe,
  selectedEmotionColor,
  adultActionImages,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  // const filterStudentDataByTimeframeEmotionHelpfulness = (studentData) => {
  //     const filteredStudentData = [];
  //     const now = moment();

  //     // console.log(studentData)
  //     if (!studentData) return filteredStudentData;
  //     studentData.forEach(studentDataPoint => {
  //         const studentDate = moment(studentDataPoint.date);
  //         if ((studentDataPoint.emotion === selectedEmotion || selectedEmotion === "all") && studentDate.isAfter(now.subtract(timeHelper(selectedTimeframe)))
  //         && studentDataPoint.helpful === true) {
  //             filteredStudentData.push(studentDataPoint);
  //         }
  //     });

  //     return filteredStudentData;
  // }

  // var filteredStudentData = filterStudentDataByTimeframeEmotionHelpfulness(studentData, selectedTimeframe)

  var practiceHelpfulnessCounts = {};
  studentData.forEach((studentDataPoint) => {
    if (!(studentDataPoint.action in practiceHelpfulnessCounts)) {
      practiceHelpfulnessCounts[studentDataPoint.action] = 1;
    } else {
      practiceHelpfulnessCounts[studentDataPoint.action] =
        practiceHelpfulnessCounts[studentDataPoint.action] + 1;
    }
  });

  const practicesArray = Object.entries(practiceHelpfulnessCounts);

  // Sort the array by count in descending order
  const sortedPractices = practicesArray.sort(
    ([, countA], [, countB]) => countB - countA
  );

  var noPracticesMessageVisibility =
    sortedPractices.length < 1 ? true : false;
  var imageSettingFilenameModifier = null;
  if (adultActionImages) {
    imageSettingFilenameModifier = "AdultCircle_BIG_FONT";
  } else {
    imageSettingFilenameModifier = "TeenCircle_BIG_FONT";
  }

  return (
    <div
      className="practice-images-grid"
      style={{ backgroundColor: selectedEmotionColor }}
    >
      <div className="practice-images-container-my-wellness">
      {noPracticesMessageVisibility && (
          <div className="no-practices-message">
            <NoPracticesFound></NoPracticesFound>
          </div>
        )}
        {sortedPractices.map(([practice, count]) => (
          <div 
            key={practice} 
            className="practice-item-my-wellness" 
            onMouseEnter={() => setHoveredItem(practice)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            {/* Toggle teen vs adult wellness-practice images based on user setting*/}
            <div className="practice-image-container-whathelps">
              <img src={
                practice.startsWith("Other")
                  ? "../images/actions/Other_BIG_FONT.png"
                  : `../images/actions/${practice} ${imageSettingFilenameModifier}.png`
              }
              alt={practice}
              className="practice-image-my-wellness" />
              {hoveredItem === practice && (
                <div className="hover-text">{practice}</div>
              )}
            </div>
            <p className="practice-caption">{`${count} ${
              count > 1 ? "times" : "time"
            }`}</p>
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default WellnessPracticeHelpfulnessDisplay;
