import React from 'react';
import { handleTime } from '../handlers/timeHandler';

const TimeCompareDropdown = ({ state, updateState }) => (
  <select
    className="filterprofiledatasuper"
    value={state.chosenTimeCompare}
    onChange={(e) => updateState(handleTime(state, e.target.value, true))}
  >    
    <option value="">Compare to past data</option>
    <option value="day">Previous day</option>
    <option value="week">Previous week</option>
    <option value="month">Previous Month</option>
    <option value="3 months">Previous 3 Months</option>
    <option value="6 months">Previous 6 Months</option>
    <option value="year">Previous Year</option>
  </select>
);

export default TimeCompareDropdown;