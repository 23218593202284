export function createStudentIDMap(allStudents) {
    return allStudents.reduce((map, student) => {
      map[student._id] = student.email;
      return map;
    }, {});
  }
  
  export function initEmotionDictionary() {
    return {
      sad: 0,
      tired: 0,
      lonely: 0,
      happy: 0,
      grateful: 0,
      calm: 0,
      stressed: 0,
      scared: 0,
      angry: 0,
    };
  }
  