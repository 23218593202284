import React from "react";
import "./EmotionsLog.css";

import StudentDataTimeframeGrid from "./StudentDataWellnessSuccessTab/StudentDataTimeframeGrid";
import StudentDataHeaderBox from "./StudentDataWellnessSuccessTab/StudentDataHeaderBox";
import EmotionsLogDisplay from "./EmotionsLogDisplay";
import { SelectSearchData, ChooseAnEmotion } from "./AlertComponents";
import { handlePercentage } from "../handlers/percentageHandler";
import EmotionsLogHeaderBox from "../EmotionsLogHeaderBox";
import StudentDataEmotionsSelector from "./StudentDataWellnessSuccessTab/StudentDataEmotionSelector";

const EmotionsLog = (props) => {
  const percentageOptions = [0, 10, 25, 50, 75];

  const handlePercentageChange = (event) => {
    let newState = handlePercentage(props.state, Number(event.target.value));
    props.updateState(newState);
  };

  const isDataSelected =
    props.state.chosenDistrict !== "Choose District" &&
    props.state.chosenSchool !== "Choose School" &&
    props.state.chosenClass !== "Choose Class";

  if (!isDataSelected) {
    return <SelectSearchData screenType={"Emotions Log"} />;
  }

  return (
    <div className="emotions-log">
      <EmotionsLogHeaderBox
        userRole={props.userRole}
        state={props.state}
      />

      <div className="emotion-selection-box">
        <div className="emotion-header">
          <p className="class-info-text">
            {props.state.chosenEmotion === "all emotions" ? (
              "CHOOSE AN EMOTION TO FILTER DATA"
            ) : (
              <>
                {props.state.chosenStudent === "All Students" ||
                props.state.chosenSchool === "All Schools"
                  ? "HAS FELT "
                  : "FELT "}
                <strong>{props.state.chosenEmotion.toUpperCase()}</strong>
              </>
            )}
          </p>
        </div>
        <StudentDataEmotionsSelector
          chosenEmotion={props.state.chosenEmotion}
          state={props.state}
          updateState={props.updateState}
        />
      </div>

      {props.state.chosenEmotion === "all emotions" ? (
        <div className="choose-emotion-box">
          <ChooseAnEmotion />
        </div>
      ) : (
        <>
          <div className="timeframe-selection-box">
            <div className="timeframe-header">
              <p className="class-info-text">
                IN THE
                <strong> LAST {props.state.chosenTime.toUpperCase()}</strong>
              </p>
            </div>
            <StudentDataTimeframeGrid
              selectedTimeframe={props.state.chosenTime}
              state={props.state}
              updateState={props.updateState}
            />
          </div>

          <div className="percentage-selection-box">
            <select
              value={props.state.chosenPercentage}
              onChange={handlePercentageChange}
              className="percentage-dropdown"
            >
              {percentageOptions.map((option) => (
                <option key={option} value={option}>
                  OVER {option}% OF THE TIME
                </option>
              ))}
            </select>
          </div>

          <div className="student-list-box">
            <div className="student-list-header">
              <p className="class-info-text">
                <strong>{props.state.studentEmotionData.length} </strong> {props.state.studentEmotionData.length > 1 ? "STUDENTS " : "STUDENT "}
                <strong>FELT {props.state.chosenEmotion} </strong>
                <img
                  src={`/images/emotions/${props.state.chosenEmotion}.png`}
                  alt={props.state.chosenEmotion}
                  className="emotion-icon-big5"
                /> 
                IN THE LAST <strong>{props.state.chosenTime} </strong>
               AND HERE's <strong> WHAT HELPS </strong>THEM:
              </p>
            </div>
            <div className="practices-display">
              <EmotionsLogDisplay
                studentEmotionData={props.state.studentEmotionData}
                chosenEmotion={props.state.chosenEmotion}
                chosenColor={props.state.chosenColor}
                studentEmailToNameMap={props.state.studentEmailToNameMap}
                adultActionImages={props.adultActionImages}
                updateState={props.updateState}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EmotionsLog;
