import "./HowDoIFeel.css";
// import BlueSidebar from "../../components/BlueSidebar";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Store, { Context } from "../../components/Store";
import { Context2 } from "../../components/Audio";
import ReactPlayer from "react-player/lazy";

const HowDoIFeel = (props) => {
  const navigate = useNavigate();
  const [playing, setPlaying] = useContext(Context2);
  const [wellnessDataPoint, setWellnessDataPoint] = useContext(Context);
  const audio_url_prefix = props.user.user.voice?props.user.user.voice.replace(" ","_"):"english_female"
  // console.log("audio_URL",audio_url_prefix)
  function sendEmotionAndNav(emotion) {
    const temp = wellnessDataPoint;
    temp.emotion = emotion;
    setWellnessDataPoint(temp);
    // console.log(wellnessDataPoint);
    navigate("/deepbreathe2");
    // create a new data point in wellness history
    //add this emotion to that data point
    //
  }

  return (
    <motion.div
      class="outerfeel"
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
    >
      {/* <BlueSidebar></BlueSidebar> */}
      <div class='reactplayer'>
      <ReactPlayer playing={playing} url={"../audio/"+audio_url_prefix+"/howdoifeel.wav"} />
      </div>
      <div class="organizeheader">
        <div class="howdoifeelrow">
          <img
            class="greennumber"
            src="../images/green1.png"
            alt="loading..."
            loading="lazy"
          />
          <h1 class="howdoifeel">&nbsp;How do I FEEL?</h1>
        </div>
        <div class="innerfeelrow">
          <div class="innerfeelcol">
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("sad")}
            >
              <img
                class="emotions"
                src="../images/emotions/sad.png"
                alt="loading..."
              />
              <div class="emotiontext">Sad</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("tired")}
            >
              <img
                class="emotionstired"
                src="../images/emotions/tired.png"
                alt="loading..."
              />
              <div class="emotiontext">Tired</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("lonely")}
            >
              <img
                class="emotions"
                src="../images/emotions/lonely.png"
                alt="loading..."
              />
              <div class="emotiontext">Lonely</div>
            </div>
          </div>
          <div class="innerfeelcol">
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("happy")}
            >
              <img
                class="emotions"
                src="../images/emotions/happy.png"
                alt="loading..."
              />
              <div class="emotiontext">Happy</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("grateful")}
            >
              <img
                class="emotions"
                src="../images/emotions/grateful.png"
                alt="loading..."
              />
              <div class="emotiontext">Grateful</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("calm")}
            >
              <img
                class="emotions"
                src="../images/emotions/calm.png"
                alt="loading..."
              />
              <div class="emotiontextcalm">Calm</div>
            </div>
          </div>
          <div class="innerfeelcol">
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("stressed")}
            >
              <img
                class="emotions"
                src="../images/emotions/stressed.png"
                alt="loading..."
              />
              <div class="emotiontext">Stressed</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("scared")}
            >
              <img
                class="emotions"
                src="../images/emotions/scared.png"
                alt="loading..."
              />
              <div class="emotiontext">Scared</div>
            </div>
            <div
              class="textandimgemotion"
              onClick={() => sendEmotionAndNav("angry")}
            >
              <img
                class="emotions"
                src="../images/emotions/angry.png"
                alt="loading..."
              />
              <div class="emotiontext">Angry</div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default HowDoIFeel;
