import "./AudioScreen.css";
import React, { useContext, useEffect } from "react";
import { Context2 } from "../../components/Audio";
import { useNavigate } from "react-router-dom";
import { FaVolumeUp } from "react-icons/fa";

const AudioScreen = () => {
  const [playing, setPlaying] = useContext(Context2);
  const navigate = useNavigate();
  function navAndAudio(isplaying) {
    setPlaying(isplaying);
    navigate("/deepbreathe");
  }
  return (
    <div class="audiocolumn">
      <div class="audiotitle">
        Would you like audio for your wellness practice?
      </div>
      <div class="audiobuttonrow">
        <button class="helpfulyes" onClick={() => navAndAudio(true)}>
          Yes
        </button>
        <button class="helpfulno" onClick={() => navAndAudio(false)}>
          No
        </button>
      </div>
      <div class="volumeicon">
        <FaVolumeUp></FaVolumeUp>
      </div>
    </div>
  );
};

export default AudioScreen;
