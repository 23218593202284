export function transform(currentDict, compareDict, updateState) {
  let counter = 0;
  const tempCounts = [];
  Object.keys(currentDict).forEach((emotion) => {
    counter += currentDict[emotion];
    tempCounts.push({
      name: emotion,
      current: currentDict[emotion],
      compare: compareDict[emotion],
    });
  });
  updateState.counter = counter;
  updateState.counts = tempCounts;
}

export function transformActions(impactfulActions, updateState) {
  const num = 3;
  const requiredObj = {
    action: {},
    emotion: {}
  };

  // console.log(impactfulActions);

  if (!impactfulActions || (Object.keys(impactfulActions.action || {}).length < 1 && Object.keys(impactfulActions.emotion || {}).length < 1)) {
    updateState.threeImpactfulActions = requiredObj;
    return;
  }

  ['action', 'emotion'].forEach(category => {
    if (impactfulActions[category]) {
      Object.keys(impactfulActions[category])
        .sort((a, b) => impactfulActions[category][b] - impactfulActions[category][a])
        .slice(0, 3)
        .forEach((key) => {
          requiredObj[category][key] = impactfulActions[category][key];
        });
    }
  });

  console.log(requiredObj);
  updateState.threeImpactfulActions = requiredObj;
}

export function transformFilteredData(studentEmotionData,percentage,updateState){
  const formattedStudentData = Object.entries(studentEmotionData).map(([name, data]) => ({
    name,
    count: data.count,
    total: data.total,
    percent: (data.count / data.total) * 100, 
    actions: data.actions
  })).filter(student => student.percent > percentage);
  updateState.chosenPercentage = percentage;
  updateState.studentEmotionData = formattedStudentData;
}
