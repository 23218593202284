import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import {
  processAllDistricts,
  processAllSchoolsInDistrict,
  processSpecificSchool,
} from "../utils/processSchools";
import {
  processSpecificClass,
  processSpecificStudent,
} from "../utils/processClasses";

export function handlePercentage(state, percentage) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let updateState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );

  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );

  if (state.chosenDistrict === "All Districts") {
    processAllDistricts(state,processDataPoint)
  } else if (state.chosenSchool === "All Schools") {
    processAllSchoolsInDistrict(state, processDataPoint);
  } else if (state.chosenClass === "All Classes") {
    processSpecificSchool(state, state.chosenSchool, processDataPoint);
  } else if (state.chosenStudent === "All Students") {
    processSpecificClass(state, state.chosenClass, processDataPoint);
  } else {
    processSpecificStudent(state, state.chosenStudent, processDataPoint);
  }

    updateState.filteredDataPoints = filterDataPoints;
  //  these should not impact this data so dont change it
    // updateState.bigFiveFilteredData = bigFiveFilteredData;

  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    state.chosenDistrict,
    state,
    emotionDict,
    updateState
  );

  transform(currentDict, compareDict, updateState);
  transformActions(impactfulActions, updateState);
  transformFilteredData(studentEmotionData,percentage,updateState)
  return updateState;
}
