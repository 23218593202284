import React from 'react';
import StudentDataCircleNumber from "./StudentDataCircleNumber";

const StudentDataHeaderBox = ({ userRole, state, selectedEmotion, handleAllEmotionsClick }) => {
  let name = state.studentEmailToNameMap[state.chosenStudent] ? state.studentEmailToNameMap[state.chosenStudent]: state.chosenStudent
  const renderHeaderContent = () => {
    switch (userRole) {
      case '123wellness':
        return (
          <p className="class-info-text">
            WHEN: <strong>{state.chosenSchool.toUpperCase()}</strong> IN <strong>{state.chosenDistrict.toUpperCase()}</strong>
          </p>
        );
      case 'superadmin':
        return (
          <p className="class-info-text">
            WHEN: <strong>{name.toUpperCase()}</strong> IN <strong>{state.chosenClass.toUpperCase()}</strong> IN <strong>{state.chosenSchool.toUpperCase()}</strong>
          </p>
        );
      case 'admin':
      case 'teacher':
        return (
          <p className="class-info-text">
            WHEN: <strong>{name.toUpperCase()}</strong> IN <strong>{state.chosenClass.toUpperCase()}</strong>
          </p>
        );
      default:
        return null;
    }
  };

  return (
    // <div className="header-box">
      <div className="class-info-box">
        {renderHeaderContent()}
      </div>
    // </div>
  );
};

export default StudentDataHeaderBox;
