import React from "react";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCalendarDay, faCalendarDays, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';



const TimeframeGrid = ({selectedTimeframe, setSelectedTimeframe, selectedEmotionColor}) => {
      
    const timeframes = [
      {'display':'2 hours', 'value':'2 hours', 'caption':'Last 2 hours'},
      {'display':'24 hours', 'value':'24 hours', 'caption':'Last 24 hours'},
      {'display':'7 days', 'value':'week', 'caption':'Last 7 days'},
      {'display':'30 days', 'value':'month', 'caption':'Last 30 days'},
      {'display':'6 months', 'value':'6 months', 'caption':'Last 6 months'},
      {'display':'12 months', 'value':'year', 'caption':'Last 12 months'}
    ];
  
    const handleTimeframeClick = (timeframe) => {
      setSelectedTimeframe(timeframe);
    };
  
    const timeFrameFontawesomeMappings = {"2 hours":faHourglassEnd,
                                          "24 hours":faHourglassEnd,
                                          "week":faCalendarDay,
                                          "month":faCalendarDay,
                                          "6 months":faCalendarDays,
                                          "year":faCalendarDays
                                          }
    return (
      <div className="timeframe-grid-container">
        <div className="timeframe-grid">
          {timeframes.map((timeframe, index) => (
            <div
            key={index}
            id={timeframe['value']}
            className={`timeframe-item ${selectedTimeframe === timeframe['value'] ? 'selected' : ''}`}
            onClick={() => handleTimeframeClick(timeframe['value'])}
            style={{ fontWeight: "bold", backgroundColor: selectedTimeframe === timeframe['value'] ? selectedEmotionColor : 'white'}}>
              <FontAwesomeIcon className="fontawesome-time-icon" icon={timeFrameFontawesomeMappings[timeframe['value']]}/>              
              <p>{timeframe['display']}</p>
              <p className="timeframe-item-subheader" style={{ fontWeight: "normal"}}>{timeframe['caption']}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default TimeframeGrid