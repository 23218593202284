import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import { getWellnessDataForDistrict } from "../utils/dataFetchers";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import { processAllDistricts, processAllSchoolsInDistrict } from "../utils/processSchools";

export function handleDistrict(
  state,
  district,
) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let newState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );
  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );

  if (district === "Choose District") {
    console.log("Choose District");
  } else if (district === "All Districts") {
    processAllDistricts(state,processDataPoint)
  } else {
    processAllSchoolsInDistrict(state, processDataPoint, district);
  }

  newState.filteredDataPoints = filterDataPoints;
  newState.chosenDistrict = district;
  newState.chosenSchool = "All Schools";
  newState.chosenClass = "All Classes";
  newState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
  newState.tooltipCounts = tooltipCounts;
  
  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    district,
    state,
    emotionDict,
    newState
  );

  transform(currentDict, compareDict, newState);
  transformActions(impactfulActions, newState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,newState)

  return newState
  // return newState;
}
