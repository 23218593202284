import React from "react";

const WellnessStreaks = (props) => {
    const possibleDayStreaks = [3, 5, 7, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 
                                175, 200, 250, 300, 365, 500, 730, 1095, 1460, 1825]

    const possibleWeekStreaks = [2, 3, 5, 10, 20, 30, 40, 52, 60, 70, 80, 90, 104, 156, 
                                208, 260, 312, 364, 416, 468, 520]
    
    const DayStreaks = (props) => {
        const currentDayStreakValue = props.curDayStreak
        const maxDayStreakValue = props.maxDayStreak
        var displayImages = possibleDayStreaks.map((streakNum) => {
            if (currentDayStreakValue && streakNum < currentDayStreakValue || maxDayStreakValue && streakNum == maxDayStreakValue){
                return([streakNum, `../images/streaks/dayStreaks/Color/${streakNum}DaysColor.png`])
            } else {
                return([streakNum, `../images/streaks/dayStreaks/Grey/${streakNum}DaysGrey.png`])
            }
        })

        return(
            <div className="streaksDisplayContainer">
                {displayImages.map((streak, index) => (
                    <div key={index} className="streakDisplayItem">
                        <img
                            key={index}
                            src={streak[1]}
                            alt={streak[0]}
                            className={'streakItemImage'}
                        />
                    </div>
                ))}
            </div>
        )
    }

    const WeekStreaks = (props) => {
        const currentWeekStreakValue = props.curWeekStreak
        const maxWeekStreakValue = props.maxWeekStreak
        var displayImages = possibleWeekStreaks.map((streakNum) => {
            if (currentWeekStreakValue && streakNum < currentWeekStreakValue || maxWeekStreakValue && streakNum == maxWeekStreakValue){
                return([streakNum, `../images/streaks/weekStreaks/Color/${streakNum}WeeksColor.png`])
            } else {
                return([streakNum, `../images/streaks/weekStreaks/Grey/${streakNum}WeeksGrey.png`])
            }
        })

        return(
            <div className="streaksDisplayContainer">
                {displayImages.map((streak, index) => (
                    <div key={index} className="streakDisplayItem">
                        <img
                            key={index}
                            src={streak[1]}
                            alt={streak[0]}
                            className={'streakItemImage'}
                        />
                    </div>
                ))}
            </div>
        )
    }

    const MaxStreak = (props) => {
        if (props.maxWeekStreak){
            return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/weekStreaks/Color/${props.maxWeekStreak}WeeksColor.png`}/>)
        } else if (props.maxDayStreak){
            return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/dayStreaks/Color/${props.maxDayStreak}DaysColor.png`}/>)
        }
        return(<img id="myAchievementsSideIconStreak" src={`../images/streaks/genericStreaks/BlankStreakBluecolor.png`}/>)
    }

    return(
        <div className="achievementSectionContainer">
            <MaxStreak props={props}></MaxStreak>
            <div className="achievementSectionContainerRightSide">
                <h2 className="achievementheaders" >STREAKS</h2>
                <div>
                    <h4 className="achievementheadersubtext"  >Day Streaks:</h4>
                    <p className="achievementExplanationP">Earn day streaks by practicing wellness multiple days in a row.</p>
                    <DayStreaks curDayStreak={props.curDayStreak}></DayStreaks>
                </div>
                <div>
                    <h4 className="achievementheadersubtextweek"  >Week Streaks:</h4>
                    <p className="achievementExplanationP">Earn week streaks when you practice wellness multiple weeks in a row.</p>
                    <WeekStreaks></WeekStreaks>
                </div>
            </div>
        </div>
    )
}

export default WellnessStreaks