import React, { useState, useEffect, useRef } from 'react';

const CustomEditor = React.memo(({ value, onCommit }) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    // Automatically focus the input when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    onCommit(e.target.value);  // Update the grid immediately on change
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onCommit(inputValue);
    }
  };

  const handleBlur = () => {
    onCommit(inputValue);  // Commit value on blur as well
  };

  return (
    <input
      ref={inputRef}
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      style={{
        width: '100%',
        height: '100%',
        border: '1px solid #ccc', // Default border
        outline: 'none',
        padding: '5px',
        backgroundColor: '#e0f7fa', // Light blue background when selected
        color: '#000', // Black text color
        transition: 'background-color 0.3s ease', // Smooth transition
      }}
    />
  );
});

export default CustomEditor;