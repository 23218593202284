import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faCalendarDays,
  faHourglassEnd,
} from "@fortawesome/free-solid-svg-icons";
import { timeframeOptions } from "../../../../components/timeframeOptions";
import { handleTime } from "../../handlers/timeHandler";

const StudentDataTimeframeGrid = ({
  selectedTimeframe,
  state,
  updateState,
}) => {
  const timeFrameFontawesomeMappings = {
    "2 hours": faHourglassEnd,
    "24 hours": faHourglassEnd,
    week: faCalendarDay,
    month: faCalendarDay,
    "3 months": faCalendarDay,
    "6 months": faCalendarDays,
    year: faCalendarDays,
  };

  return (
    <div className="timeframe-grid-container-studentdata">
      <div className="timeframe-grid-studentdata">
        {timeframeOptions
          .filter(
            (timeframe) =>
              !["1 hour", "10 minutes", "2 years"].includes(timeframe.value)
          )
          .map((timeframe, index) => (
            <div
              key={index}
              id={timeframe.value}
              className={`timeframe-item-studentdata ${
                selectedTimeframe === timeframe.value ? "selected" : ""
              }`}
              onClick={() => {
                let newState = handleTime(state, timeframe.value);
                updateState(newState);
              }}
            >
              <FontAwesomeIcon
                icon={timeFrameFontawesomeMappings[timeframe.value]}
              />
              <p className="time-display-studentdata">
                {timeframe.value.toUpperCase()}
              </p>
              <p className="timeframe-item-subheader-studentdata">
                Last {timeframe.value}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default StudentDataTimeframeGrid;
