import React from 'react';
import { handleEmotion } from '../../handlers/emotionHandler2';

const StudentDataEmotionsSelector = ({ chosenEmotion, state, updateState }) => {
    const emotionEmojiImgMapping = [
        ["sad", "images/emotions/sad.png"],
        ["tired", "images/emotions/tired.png"],
        ["lonely", "images/emotions/lonely.png"],
        ["happy", "images/emotions/happy.png"],
        ["grateful", "images/emotions/grateful.png"],
        ["calm", "images/emotions/calm.png"],
        ["stressed", "images/emotions/stressed.png"],
        ["scared", "images/emotions/scared.png"],
        ["angry", "images/emotions/angry.png"]
    ];

    const handleImageClick = (alt) => {
        let newState = handleEmotion(state, alt);
        updateState(newState);
    };

    const isAllSelected = chosenEmotion === 'all emotions';

    return (
        <div className="image-grid-student-data">
            {emotionEmojiImgMapping.map(([alt, src], index) => (
                <div 
                    key={index} 
                    className={`emotion-item ${chosenEmotion === alt ? 'selected' : ''} ${isAllSelected ? 'all-selected' : ''}`}
                >
                    <img
                        src={src}
                        alt={alt}
                        className={`grid-image ${chosenEmotion === alt ? 'selected' : ''}`}
                        onClick={() => handleImageClick(alt)}
                    />
                    <p className="emotionEmojiLabel">{alt}</p>
                </div>
            ))}
        </div>
    );
};

export default StudentDataEmotionsSelector;
