import "./AccountProvisioningError.css";
import { useState, useEffect } from "react";
import { Footer } from "../SigninScreen/SigninScreen";
import { CircularProgress } from "@mui/material";

const ProvisioningErrorMessage = () => {
  return (
    <>
      <img
        className="logo"
        src="../images/123WellnessLogo-01.png"
        alt="123 Wellness Logo"
        loading="lazy"
      ></img>
      <div className="maincontainer">
        <p className="provisioningError">
          We're sorry, but your account hasn't been provisioned yet! Once your
          district admin provisions your account, you'll be able to log in.
          Please check back soon!
        </p>
        <p
          className="goBackLink"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go Back
        </p>
      </div>
      <Footer />
    </>
  );
};

const LoadingMessage = () => {
  return (
    <div className="maincontainer">
      <CircularProgress
        style={{
          color: "#fff",
          width: "5vmax",
          height: "5vmax",
        }}
      />
    </div>
  );
};

/* 
    This maintains the style of the login and no account screen
*/
export default function AccountProvisioningError() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 30000);
  }, []);

  return (
    <div className="outer">
      {loading ? <LoadingMessage /> : <ProvisioningErrorMessage />}
    </div>
  );
}
