import { COLLECTIONS, INDICES } from "../types/utils";

const PAGE_MAX = 50000;

/* 
  Runs an aggregation pipeline that runs a paginated query with a max length of 
  50,000 records. Each record that is return contains a pagination token, which 
  can be passed into the query to continue retrieving results from a particular
  cursor location. If no `projection` is passed, this function will return all 
  fields by default. Similarly, if no `operator` or `collector` is provided, 
  it'll return all records in the provided `collection`.

  Args: 
    -- `realmUser`:       The authenticated user instance that allows us to create 
                          the aggregation pipeline.
    -- `collection`:      The collection in the mongoDB database you're pulling data
                          from.
    -- `operator`:        The operator object to use during the $search stage of 
                          the aggregation pipeline.
    -- `collector`:       The collector to use during the $search stage of the
                          aggregation pipeline.
    -- `projection`:      An object representing the fields you want back from the 
                          query. If this isn't specified, then it'll select return 
                          all fields in the collection by default.                    
    -- `sort`:            An optional argument that can sort the data based on 
                          compatible fields in your schemda. (Date, String, Number)
    -- `paginationToken`: An optional pagination token provided by a returned DB 
                          record. If provided, the query will search after the cursor
                          location provided by this token.                
    -- `limit`:           An optional page limit than can reduce the number of items
                          returned by the query per page. By default, the `limit` is 
                          the PAGE_MAX.
    -- `maxPages`:        An optional page limit that will limit the number of pages
                          returned by the query.
                    
  Returns: The type corresponding to the `collection` passed into the function
*/
export const runPaginatedQuery = async (
  realmUser,
  collection,
  operator = null,
  collector = null,
  projection = null,
  sort = null,
  paginationToken = null,
  limit = PAGE_MAX,
  maxPages = null
) => {
  if (!collection || !(collection in COLLECTIONS)) {
    console.error(
      `Collection '${collection}' doesn't exist in the database. ` +
        "Please choose a valid collection to run your query on."
    );
    return null;
  }

  // Grab a reference to the DB using the authenticated user instance.
  const dbRef = realmUser.mongoClient("mongodb-atlas").db("123wellness");
  const collectionRef = dbRef.collection(collection);

  // If the user doesn't provide a projection, return all the fields for that
  // collection's associated type by default.
  if (projection === null) {
    projection = {};
    for (const key in COLLECTIONS[collection]) {
      projection[key] = 1;
    }
  }

  // If the user doesn't provide an operator or collector for the search stage
  // of the aggregation pipeline, return all records by default.
  let operation;
  if (operator === null && collector === null) {
    operation = {
      exists: {
        path: "_id",
      },
    };
  } else {
    operation = operator ? operator : collector;
  }

  let numPages = 0;
  let allRecords = [];
  while (!maxPages || numPages < maxPages) {
    // Build the object for the search stage of the pipeline
    const searchStage = {
      $search: {
        index: INDICES[collection],
      },
    };
    if (operation) {
      searchStage.$search = {
        ...searchStage.$search,
        ...operation,
      };
    }
    if (sort) {
      searchStage.$search = {
        ...searchStage.$search,
        sort: {
          ...sort,
        },
      };
    }
    if (paginationToken) {
      searchStage.$search.searchAfter = paginationToken;
    }

    // Run the query with the projection
    const retrievedPage = await collectionRef.aggregate([
      searchStage,
      {
        $limit: limit,
      },
      {
        $project: {
          ...projection,
          paginationToken: {
            $meta: "searchSequenceToken",
          },
        },
      },
    ]);
    allRecords = allRecords.concat(retrievedPage);
    numPages++;

    if (retrievedPage.length === limit) {
      paginationToken = retrievedPage[limit - 1].paginationToken;
    } else {
      break;
    }
  }

  return allRecords;
};
