import { loadSchoolFromCleverId } from "../../realm/graphqlQueries";
import {
  loadUserDistrict,
  loadClassData,
  loadStudentData,
  getWellnessData,
} from "./utils/dataFetchers";
import {
  getAllStudents,
  convertDistrictStudentIdsToEmails,
  constructStudentData,
} from "./utils/dataProcessors";
import { initEmotionDictionary } from "./utils/util";

export async function getTeacherData(state, realmUser, user) {
  try {
    const userDistrict = await loadUserDistrict(user, realmUser);
    const [classRef, districts] = await loadClassData(
      user,
      realmUser,
      userDistrict
    );
    const allStudents = getAllStudents(classRef);
    const { studentProfiles, studentIdToEmailMap, studentEmailToNameMap } =
      await loadStudentData(user, allStudents, realmUser);
    const allStudentIDs = studentProfiles.users.map((x) => x._id);
    const wellnessData = await getWellnessData(allStudentIDs, realmUser);
    const studentData = constructStudentData(studentProfiles, wellnessData);

    let districtsWithEmails = convertDistrictStudentIdsToEmails(
      districts,
      studentIdToEmailMap
    );

    // !!!TEMPORARY MEASURE!!!
    // For teachers in non-clever schools, add the class information into
    // the district object above.
    if (user.user.user === "teacher" && !user.user.cleverId) {
      const { district } = user.user;
      const districtObj = {};
      districtObj[district] = {};
      for (const _class of classRef.classes) {
        if (!(_class.school in districtObj[district])) {
          districtObj[district][_class.school] = {};
        }

        districtObj[district][_class.school][_class.name] = _class.students;
      }
      console.log(JSON.stringify(districtObj, null, "\t"));
      districtsWithEmails = districtObj;
    }

    let school = undefined;
    if (user.user.schools.length > 0) {
      school = (await loadSchoolFromCleverId(user.user.schools[0], realmUser))
        .schools[0];
    }

    return {
      districts: districtsWithEmails,
      allWellnessPoints: wellnessData.wellnesses,
      allStudents: studentProfiles.users,
      studentData,
      currentDictionary: initEmotionDictionary(),
      compareDictionary: initEmotionDictionary(),
      loading: false,
      counter: 0,
      chosenSchool: school ? school.name : user.user.school,
      chosenDistrict: userDistrict.district.name,
      studentEmailToNameMap,
    };
  } catch (error) {
    console.error("Error in getTeacherData:", error);
    throw error;
  }
}
