import React from 'react';
import "./CustomTooltip.css"

const CustomTooltip = ({ active, payload, label, state }) => {
  if (!active || !payload || payload.length === 0 || !state || !state.tooltipCounts) {
    return null;
  }

  const emotion = label;
  const tooltipData = state.tooltipCounts[emotion];

  // Calculate totals and percentages
  const compareTotal = state.counts.reduce((total, data) => total + (data.compare || 0), 0);
  const currentTotal = state.counts.reduce((total, data) => total + data.current, 0);
  const currentCount = payload[0]?.value || 0;
  const compareCount = payload[1]?.value || 0;
  const currentPercentage = currentTotal ? Math.round((currentCount / currentTotal) * 100) : 0;
  const comparePercentage = compareTotal ? Math.round((compareCount / compareTotal) * 100) : 0;

  const currentPeriod = state.chosenTime || 'Current period';
  const comparePeriod = state.chosenTimeCompare || 'Compare period';

  if (!tooltipData || Object.keys(tooltipData).length === 0) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-header">
          <img 
            src={`/images/emotions/${emotion}.png`}
            alt={emotion}
            className="emotion-icon"
          />
          <span className="emotion-name">{emotion}</span>
        </div>
        <div className="tooltip-summary">
          <p>Last {currentPeriod}: {currentCount} times ({currentPercentage}% of all emotions)</p>
          <p>Previous {comparePeriod}: {compareCount} times ({comparePercentage}% of all emotions)</p>
        </div>
        <p>No student-specific data available for this emotion.</p>
      </div>
    );
  }

  const sortedStudents = Object.entries(tooltipData)
    .sort(([, a], [, b]) => b - a)
    .map(([email, count]) => ({
      name: state.studentEmailToNameMap?.[email] || email,
      count
    }));

  return (
    <div className="custom-tooltip">
      <div className="tooltip-header">
        <img 
          src={`/images/emotions/${emotion}.png`}
          alt={emotion}
          className="emotion-icon"
        />
        <span className="emotion-name">{emotion}</span>
      </div>
      <div className="tooltip-summary">
        <p>Last {currentPeriod}: {currentCount} times ({currentPercentage}% of {currentTotal})</p>
        <p>Previous {comparePeriod}: {compareCount} times ({comparePercentage}% of {compareTotal})</p>
      </div>
      <div className="scroll-area">
        {sortedStudents.length > 0 ? (
          <table className="student-list">
            <thead>
              <tr>
                <th>Student</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              {sortedStudents.map((student, index) => (
                <tr key={index}>
                  <td>{student.name}</td>
                  <td>{student.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No student-specific data available.</p>
        )}
      </div>
    </div>
  );
};

export default CustomTooltip;
