export const WellnessBadges = {
  active: "number",
  creative: "number",
  thankful: "number",
  explorer: "number",
  strengths: "number",
  courage: "number",
  learner: "number",
  kindness: "number",
  determined: "number",
  positive: "number",
};

// TODO: Ideally replace this w/ a TS type / interface
export const User = {
  _id: "id",
  agreedToTerms: "boolean",
  class: "Array<string>",
  count: "number", // TODO: this should have a custom type (int)
  dataUse: "boolean",
  district: "string",
  email: "string", // TODO: this should have a custom type
  metadata: "object", // TODO: this should have a custom type
  school: "string",
  user: "string", // TODO: this should have a custom type
  wellnessTimer: "string",
  voice: "string",
  displayName: "string",
  cleverId: "string",
  schools: "Array<string>",
  maxDayStreak: "number",
  maxWeekStreak: "number",
  curDayStreak: "number",
  curWeekStreak: "number",
  coins: "number",
  adultActionImages: "boolean",
  usesCastle: "boolean",
  badges: WellnessBadges,

  // This is a field in the schema but it's deprecated, so
  // there's no reason for the user to see this.
  //   cleverToken: "string",
};
