import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authentication, firestore } from "../../firebase";
import "./DisclaimerScreen.css";
import { UserContext } from "../../realm/user.context";
import { updateProfile } from "../../realm/graphqlQueries";

const DisclaimerScreen = (props) => {
  const { realmUser } = useContext(UserContext);
  const [disclaimer, setDisclaimer] = useState(false);
  const [privacy, setPrivacy] = useState(true);
  const [sharing, setSharing] = useState(false);
  const navigate = useNavigate();

  // If authenticating with clever, skip getting the name
  const getDisplayName = () => {
    if (!authentication.currentUser) {
      return "";
    } else {
      return authentication.currentUser.displayName == null
        ? ""
        : " " + authentication.currentUser.displayName.split(" ")[0];
    }
  };

  async function senddata() {
    const agreedToTerms = disclaimer;

    const setObject = {
      wellnesstimer: "30 seconds",
      agreedToTerms: agreedToTerms,
      dataUse: privacy,
      email: props.user.user.email,
    };

    try {
      const response = await updateProfile(
        props.user.user.email,
        realmUser,
        setObject
      );

      if (response && response.updateOneUser && response.updateOneUser.email) {
        console.log("Update successful for:", response.updateOneUser.email);
        navigate("/");
      } else {
        console.error("Update failed!");
      }
    } catch (error) {
      console.error("Failed to update user:", error);
    }
    // Force a full page reload
    window.location.reload();
  }

  return (
    <div class="disclaimercolumn">
      <img
        class="bottomdesignrotate"
        src="../images/overlaping colors.png"
        alt="design"
        loading="lazy"
      ></img>
      <div class="disclaimertitle">Welcome{getDisplayName()}!</div>
      <div class="pleaseanswer">
        Please answer these questions before you get started
      </div>
      {/* <img
        class="bottomdesign2"
        src="../images/overlaping colors.png"
        alt="design"
      ></img> */}

      <div class="containdimension">
        <div class="disclaimerbigtext">Disclaimer</div>
        <div class="disclaimerlittletextlong">
          By clicking this box, I acknowledge that this app, and the 1-2-3
          Wellness™ program, are educational in nature and intended to serve as
          a complement to support provided by talented people including
          teachers, parents, counselors, and other professionals. The app and
          program are not designed to treat or diagnose any mental health
          issues. You are encouraged to stop using this app and affiliated
          resources at any time. Please let your teacher, parent, guardian,
          counselor, peer, or principal know if you have any concerns at all.
          The 1-2-3 Wellness™ program offers support that is educational in
          nature. The program is intended to serve as a part of a larger
          framework of support orchestrated by schools and organizations. 1-2-3
          Wellness™ is not intended to replace other vital supports or to treat
          or diagnose any illness. The fidelity of the 1-2-3 Wellness™ program
          occurs when the program is used in its entirety, complete with ongoing
          professional development and the full spectrum of resources, and in
          conjunction with the support of your organization’s educational and
          mental health professionals. All resources and information provided
          via GrowthWell LLC programs are entirely voluntary and individual
          participants are encouraged to use, or not use, different resources
          according to what supports their unique well-being needs as guided by
          your school and/or organizational personnel. Please contact GrowthWell
          LLC immediately with any problems or concerns at info@123wellness.org.
          You are invited to reach out to our GrowthWell LLC staff at any time
          and please continue to consult educational and mental health
          professionals as part of ongoing efforts to meet student and other
          stakeholder needs.
        </div>
        <div className="disclaimerlittletext">
          By clicking the check box below you agree to the following: <br />
          <a
            style={{ color: "white" }}
            href="https://drewschwartz.com/terms-of-service"
          >
            Terms of Service
          </a>
          ,{" "}
          <a
            style={{ color: "white" }}
            href="https://drewschwartz.com/privacy-policy"
          >
            Privacy Policy
          </a>
          , and{" "}
          <a
            style={{ color: "white" }}
            href="https://drewschwartz.com/data-governance"
          >
            Data Governance
          </a>
        </div>
        <div class="disclaimerrow">
          <div class="checkboxtext">I agree</div>
          <input
            onChange={() => {
              setDisclaimer(!disclaimer);
            }}
            class="checkbox"
            id="disclaimercheck"
            type="checkbox"
          />
        </div>
        <div class="disclaimerbigtext">Data Privacy</div>
        <div class="disclaimerlittletext">
          I give permission for my teacher(s) and building administrators to
          view my data.
        </div>
        <div class="disclaimerrow">
          <div class="iagree">I agree</div>
          <input
            defaultChecked="true"
            onChange={() => {
              setPrivacy(!privacy);
            }}
            class="checkbox"
            type="checkbox"
          />
        </div>
        {/* <div class="disclaimerbigtext">Data Sharing</div>
        <div class="disclaimerlittletext">
          Every individual is encouraged to seek support from others and
          discontinue the use of the program or app at any time if it is not
          helpful. Your individual data will not be used by 1-2-3 Wellness or
          GrowthWell LLC. All data shared with 1-2-3 Wellness will be collected
          in the aggregate (so no individual names are shared) to help future
          kids and adults. Have a great experience and a great job building your
          healthy wellness habits!
        </div>
        <div class="disclaimerrow">
          <div class="checkboxtext">I understand</div>
          <input
            class="checkbox"
            id="sharingcheck"
            onChange={() => {
              setSharing(!sharing);
            }}
            type="checkbox"
          />
        </div> */}
        <div className="disclaimerlittletext">
          Have an excellent experience. Great job building your healthy wellnes
          habits!
        </div>
        {disclaimer ? (
          <button
            onClick={() => senddata()}
            class="disclaimerbutton"
            style={{
              height: "4vh",
              width: "10vw",
              borderRadius: "15px",
              color: "black",
            }}
          >
            Continue
          </button>
        ) : (
          <button
            disabled
            class="disabledbutton"
            style={{ height: "4vh", width: "10vw", borderRadius: "15px" }}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};
export default DisclaimerScreen;
