const CircleNumber = ({ number }) => {
    return (
      <div className="circle-number-container">
        <div className="circle-number">
          {number}
        </div>
      </div>
    );
  };
  
  export default CircleNumber;