// TODO: Ideally replace this w/ a TS type / interface, and
// create custom types/verifiers for unique ID's, emotions,
// and actions.
export const Wellness = {
  _id: "id",
  action: "string",
  date: "date",
  emotion: "string",
  helpful: "boolean",
  userID: "id",
};
