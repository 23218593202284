import moment from "moment";
import { createDataPointProcessor } from "../utils/dataPointProcessor";
import {
  initializeDictionaries,
  initializeEmotionDict,
} from "../utils/dictionaries";
import { updateEmotions } from "./emotionHandler";
import { transform, transformActions, transformFilteredData } from "../utils/transformers";
import {
  processSpecificClass,
  processSpecificStudent,
} from "../utils/processClasses";

export function handleStudent(state, student) {
  const { currentDict, compareDict } = initializeDictionaries();
  const emotionDict = initializeEmotionDict();
  let impactfulActions = {action:{}, emotion:{}};
  let newState = {};
  let filterDataPoints = [];
  let studentEmotionData = {};
  const bigFiveFilteredData = [];
  let tooltipCounts = initializeEmotionDict();

  const currentDate = moment().subtract(state.filterAmountCurrent);
  const compareDate = moment().subtract(
    state.filterAmountCurrent + state.filterAmountCompare
  );
  const processDataPoint = createDataPointProcessor(
    currentDate,
    compareDate,
    state.chosenEmotion,
    currentDict,
    compareDict,
    impactfulActions,
    filterDataPoints,
    studentEmotionData,
    bigFiveFilteredData,
    state.selectedTimes,
    state.selectedSubOptions,
    state.helpfulFilter,
    tooltipCounts
  );

  if (student === "All Students") {
    processSpecificClass(state, state.chosenClass, processDataPoint);
  } else {
    processSpecificStudent(state, student, processDataPoint);
  }

  newState.chosenStudent = student;
  newState.filteredDataPoints = filterDataPoints;
  newState.bigFiveFilteredData = bigFiveFilteredData.sort((a, b) => new Date(b.date) - new Date(a.date))
  newState.tooltipCounts = tooltipCounts;
  updateEmotions(
    state.feeling,
    state.filterAmountCurrent,
    state.district,
    state,
    emotionDict,
    newState
  );

  transform(currentDict, compareDict, newState);
  transformActions(impactfulActions, newState);
  transformFilteredData(studentEmotionData,state.chosenPercentage,newState)
  return newState;
}
