//https://github.com/sourabhbagrecha/expengo/blob/master/src/contexts/user.context.js

import { createContext, useState, useMemo } from "react";
import { App, Credentials } from "realm-web";
import { APP_ID } from "./constants";

// Creating a Realm App Instance
const app = new App(APP_ID);

// Creating a user context to manage and access all the user related functions
// across different component and pages.
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [realmUser, setRealmUser] = useState(null);
  const [intervalID, setIntervalID] = useState(null);

  // Gets a valid user access token to authenticate requests
  const getValidAccessToken = async () => {
    // An already logged in user's access token might be stale. To
    // guarantee that the token is valid, refresh it if necessary.
    const userStatus = await app.currentUser;
    if (userStatus !== null) {
      await app.currentUser.refreshCustomData();
      setRealmUser(app.currentUser);
      return app.currentUser;
    }
  };
  const keepDataFresh = async () => {
    console.log("refresh data");
    await app.currentUser.refreshCustomData();
    setRealmUser(app.currentUser);
  };
  // Function to login user into our Realm using the Firebase ID Token
  const logInRealmUser = async (jwt) => {
    // Create a custom jwt credential
    //const jwt = await authenticateWithExternalSystem();
    const realmCredentials = Credentials.jwt(jwt);
    const userStatus = await app.currentUser;
    if (userStatus === null) {
      try {
        const authedUser = await app.logIn(realmCredentials);
        setRealmUser(authedUser);
        console.log(
          "Successfully logged into REALM ",
          authedUser.profile.email
        );
        if (intervalID === null) {
          const interval = setInterval(keepDataFresh, 600000);
          setIntervalID(interval);
        }
        return authedUser;
      } catch (err) {
        console.error("Failed to log in", err);
        return null;
      }
    } else {
      // console.log("User is already logged into realm");
      setRealmUser(app.currentUser);
      return app.currentUser;
    }
  };

  const loginToRealmWithClever = async (code) => {
    // get the URL without the query
    // const redirect_uri = "https://clever123wellness.123-wellness.pages.dev/";
    const redirect_uri = "https://app.123wellness.org/";
    // const redirect_uri = "https://securesync.123wellness.pages.dev/";
    // const redirect_uri = "https://clever123wellness.123wellness.pages.dev/";

    const realmCredentials = Credentials.function({ code, redirect_uri });

    // TODO: this is the same as above... decompose?
    try {
      const authedUser = await app.logIn(realmCredentials);
      setRealmUser(authedUser);
      // console.log("Successfully logged into REALM ", authedUser.profile.email);
      if (intervalID === null) {
        const interval = setInterval(keepDataFresh, 600000);
        setIntervalID(interval);
      }
      return authedUser;
    } catch (err) {
      console.error("Failed to log in", err);
      return null;
    }
  };

  // Function to logout user from our Realm
  const logOutRealmUser = async () => {
    if (!app.currentUser) return false;
    if (intervalID !== null) {
      clearInterval(intervalID);
      setIntervalID(null);
    }
    try {
      await app.currentUser.logOut();
      console.log("Logged out Realm User");
      // Setting the user to null once loggedOut.
      setRealmUser(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  return (
    <UserContext.Provider
      value={{
        realmUser,
        setRealmUser,
        logInRealmUser,
        logOutRealmUser,
        getValidAccessToken,
        loginToRealmWithClever,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
