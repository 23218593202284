import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { NoData } from "../AlertComponents";

const MAIN_CATEGORIES = [
   "Imagine positive things in the future",
   "Laugh at something funny", 
   "Remember that difficult times pass",
   "Remind myself of two good things in my life",
   "Tell myself something nice",
   "Get some sun in my eyes",
   "Count to ten taking a deep breath each time",
   "Imagine a peaceful place",
   "Listen to music or read",
   "Politely move away from a distraction",
   "Squeeze my fist",
   "Use my growth mindset",
   "Stretch",
   "Move my body",
   "Smile big",
   "write in my journal",
   "Think of 3 things I am grateful for",
   "Give someone a compliment",
   "Be mindful. Be where my feet are",
   "Imagine positive things in the future",
   "Squeeze my fist",
   "Help others",
   "Create art",
   "Give Someone a High Five"
  ];

const StudentDataWellnessPracticeHelpfulnessDisplay = ({
  studentData,
  selectedEmotionColor,
  adultActionImages,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const mainCategories = MAIN_CATEGORIES.map(p => p.trim().toLowerCase());

  // Filter student data to only include helpful actions
  const filteredStudentData = studentData.filter(dataPoint => {
    return dataPoint.helpful;
  });

  const practiceHelpfulnessCounts = filteredStudentData.reduce((counts, dataPoint) => {
    const actionLowerCase = dataPoint.action ? dataPoint.action.trim().toLowerCase() : "";

    const practiceCategory = mainCategories.includes(actionLowerCase)
      ? dataPoint.action
      : "Other";

    counts[practiceCategory] = (counts[practiceCategory] || 0) + 1;    return counts;
  }, {});

  const sortedPractices = Object.entries(practiceHelpfulnessCounts).sort(
    ([, countA], [, countB]) => countB - countA
  );

  var imageSettingFilenameModifier = null
  if (adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }

  if (sortedPractices.length === 0) {
    return <NoData></NoData>;
  }
  return (
    <div
      className="practice-images-container-student-data"
      style={{ backgroundColor: selectedEmotionColor }}
    >
      {sortedPractices.map(([practice, count]) => {
        const isOther = practice.toLowerCase().startsWith("other");
        const imageSrc = isOther
          ? "../images/actions/Other_BIG_FONT.png"
          // Toggle teen vs adult wellness-practice images based on user setting
          : `../images/actions/${practice} ${imageSettingFilenameModifier}.png`;

        return (
          <div
            className="next-steps-practice-item"
            key={practice}
            onMouseEnter={() => setHoveredItem(practice)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <div className="practice-image-container">
              <img src={imageSrc} alt={practice} className="practice-image" />
              {hoveredItem === practice && (
                <div className="hover-text">
                  {practice}
                </div>
              )}
            </div>
            <p className="practice-caption-student-data">
              {count} {count > 1 ? "times" : "time"}
            </p>
          </div>
        );
      })}
    </div>
  );

};

export default StudentDataWellnessPracticeHelpfulnessDisplay;
