// TODO: Ideally replace this w/ a TS type / interface
export const School = {
  _id: "id",
  admins: "Array<string>",
  classes: "Array<string>",
  district: "string",
  name: "string",
  cleverId: "string",
  archived: "boolean",
};
