// In processSchools.js
export function processSpecificSchool(state, school, processDataPoint) {
  let addedStudents = new Set();
  
  if (!state.districts || !state.districts[state.chosenDistrict] || !state.districts[state.chosenDistrict][school]) {
    console.warn(`School data not found for ${school} in district ${state.chosenDistrict}`);
    return;
  }

  Object.values(state.districts[state.chosenDistrict][school]).forEach(
    (className) => {
      if (className) {
        Object.values(className).forEach((student) => {
          if (student && state.studentData && state.studentData[student] && !addedStudents.has(student)) {
            addedStudents.add(student);
            state.studentData[student].forEach((dataPoint) => {
              if (dataPoint !== null && dataPoint !== undefined) {
                processDataPoint(dataPoint, student);
              }
            });
          }
        });
      }
    }
  );
}

// Similar changes for processAllSchoolsInDistrict, processSpecificClass, and processSpecificStudent

export function processAllSchoolsInDistrict(state, processDataPoint, district="") {
  if (district === "") district = state.chosenDistrict;
  let addedStudents = new Set();
  Object.values(state.districts[district]).forEach((school) => {
    Object.values(school).forEach((className) => {
      Object.values(className).forEach((student) => {
        // console.log(student)
        // let student = studentData.email
        if (state.studentData[student] && !addedStudents.has(student)) {
          addedStudents.add(student);
          state.studentData[student].forEach((dataPoint) =>
            processDataPoint(dataPoint, student)
          );
        }
      });
    });
  });
}

export function processAllDistricts(state, processDataPoint) {
  console.log(state.studentData);
  let addedStudents = new Set();
  Object.values(state.districts).forEach((district) => {
    Object.values(district).forEach((school) => {
      Object.values(school).forEach((className) => {
        Object.values(className).forEach((student) => {
          if (state.studentData[student] && !addedStudents.has(student)) {
            addedStudents.add(student);
            state.studentData[student].forEach((dataPoint) =>
              processDataPoint(dataPoint, student)
            );
          }
        });
      });
    });
  });
}

// export function processAllSchoolsInDistrict(state, processDataPoint) {
//     Object.values(state.districts[state.chosenDistrict]).forEach(
//       (schoolStudents) => {
//         schoolStudents.forEach((student) => {
//           if (state.studentData[student]) {
//             state.studentData[student].forEach(processDataPoint);
//           }
//         });
//       }
//     );
//   }
