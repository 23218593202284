import React, { useState, useEffect, useMemo } from "react";
import "./EmotionsLog.css";
import { NoData } from "./AlertComponents";

const EmotionsLogDisplay = ({
  studentEmotionData,
  chosenColor,
  studentEmailToNameMap,
  adultActionImages
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [displayedData, setDisplayedData] = useState([]);
  const [dataIndex, setDataIndex] = useState(50);
  const getTopActions = (studentEmail) => {
    const studentEntry = studentEmotionData.find(
      (student) => student.name === studentEmail
    );
  
    if (!studentEntry || !studentEntry.actions) return [];
  
    const actionCounts = studentEntry.actions;
  
    return Object.entries(actionCounts)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3);
  };
  
  const sortedStudentData = studentEmotionData.sort((a, b) => {
    if (b.count === a.count) {
      return b.percent - a.percent;
    }
    return b.count - a.count;
  });
  
  useEffect(() => {
    setDisplayedData(sortedStudentData.slice(0, 50));
    setDataIndex(50);
  }, [sortedStudentData]);
  const loadMoreData = () => {
    const newIndex = dataIndex + 50;
    setDisplayedData(sortedStudentData.slice(0, newIndex));
    setDataIndex(newIndex);
  };
  if (sortedStudentData.length === 0) {
    return (
      <NoData/>
    )
  }

  var imageSettingFilenameModifier = null
  if (adultActionImages){
      imageSettingFilenameModifier = 'AdultCircle_BIG_FONT'
  } else {
      imageSettingFilenameModifier = 'TeenCircle_BIG_FONT'
  }

  return (
    <div
      className="emotions-log-display"
      style={{ backgroundColor: chosenColor }}
    >
      <div className="student-grid">
        {displayedData.map((student, index) => {
          let name = studentEmailToNameMap[student.name] ? studentEmailToNameMap[student.name] : student.name;
          const topActions = getTopActions(student.name);
          return (
            <div key={index} className="student-item">
              <div className="student-name">{name}</div>
              <div className="student-data">
                {student.count} {student.count === 1 ? 'time' : 'times'} (
                {student.percent.toFixed(2)}%)
              </div>
              <div className="student-top-actions">
                {topActions.length > 0 ? (
                  <div className="top-actions-container">
                    {topActions.map(([action, count]) => {
                      const isOther = action.toLowerCase().startsWith("other");
                      const imageSrc = isOther
                        ? "../images/actions/Other_BIG_FONT.png"
                        : `../images/actions/${action} ${imageSettingFilenameModifier}.png`;

                      return (
                        <div
                          className="top-action-wrapper"
                          key={action}
                          onMouseEnter={() => setHoveredItem(action)}
                          onMouseLeave={() => setHoveredItem(null)}
                        >
                          <div className="top-action-circle">
                            <img src={imageSrc} alt={action} className="top-action-image" />
                            {hoveredItem === action && (
                              <div className="hover-text">
                                {action}
                              </div>
                            )}
                          </div>
                          <p className="top-action-count">
                            {count} {count > 1 ? "times" : "time"}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>No helpful actions yet</div>
                )}
              </div>
              
            </div>
          );
        })}
      </div>
      {displayedData.length < sortedStudentData.length && (
        <div className="load-more">
          <button onClick={loadMoreData}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default EmotionsLogDisplay;
