import React, { useState, useEffect } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Error } from "@mui/icons-material";

const messages = [
  "Pulling your data from the database...",
  "Structuring the data...",
  "Just a moment more...",
  "Hang tight...",
];

export const LoadingOverlayExtended = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const messageInterval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 7000); // Change message every 7 seconds

    const progressInterval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 200); // Update progress every 0.2 seconds

    const errorTimeout = setTimeout(() => {
      setErrorMessage("An error may have occurred. Please refresh the page.");
    }, 30000); // Show error message after 30 seconds

    return () => {
      clearInterval(messageInterval);
      clearInterval(progressInterval);
      clearTimeout(errorTimeout);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        animation: "backgroundAnimation 10s infinite alternate",
      }}
    >
      {errorMessage === "" ? (
        <CircularProgress
          style={{
            height: "80px",
            width: "80px",
            color: "#ffffff",
          }}
        />
      ) : (
        <Error
          style={{
            height: "80px",
            width: "80px",
            color: "#ffffff",
          }}
        ></Error>
      )}
      <p
        style={{
          color: "#ffffff",
          fontSize: "18px",
          marginTop: "20px",
          fontFamily: "Arial, sans-serif",
        }}
      >
        {errorMessage || messages[messageIndex]}
      </p>
      {errorMessage === "" && (
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{
            width: "80%",
            marginTop: "20px",
            backgroundColor: "#444444",
            color: "#ffffff",
          }}
        />
      )}
      <style>{`
        @keyframes backgroundAnimation {
          0% { background-color: rgba(0, 0, 0, 0.7); }
          100% { background-color: rgba(0, 0, 0, 0.9); }
        }
      `}</style>
    </div>
  );
};
