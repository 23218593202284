import { getClassAssociationsForStudents2 } from "../../realm/graphqlQueries";
import {
  loadUserDistrict,
  loadAllStudents,
  getWellnessData,
  loadSchoolsInfo,
} from "./utils/dataFetchers";
import {
  createStudentIDMap,
  constructStudentData,
  constructAdminStudentData,
  constructSuperAdminStudentData,
  createStudentMaps,
} from "./utils/dataProcessors";
import { initEmotionDictionary } from "./utils/util";

export async function getSuperadminData(state, realmUser, user, anonamizeData) {
  try {
    const userDistrict = await loadUserDistrict(user, realmUser);
    const allStudents = await loadAllStudents(user, realmUser);
    const allStudentIDs = allStudents.map((student) => student._id);
    const [wellnessData, schoolsInfo] = await Promise.all([
      getWellnessData(allStudentIDs, realmUser),
      loadSchoolsInfo(user, userDistrict, realmUser),
    ]);
    console.log("superadmin")

    const studentsClasses = await getClassAssociationsForStudents2(
      userDistrict.district,
      schoolsInfo,
      allStudents,
      realmUser
    );
    const studentData = constructStudentData(allStudents, wellnessData);

    const {
      idToEmail: studentIdToEmailMap,
      emailToName: studentEmailToNameMap,
    } = createStudentMaps(allStudents, anonamizeData);
    return {
      studentEmailToNameMap,
      districts: { [userDistrict.district.name]: studentsClasses },
      allWellnessPoints: wellnessData.wellnesses,
      allStudents,
      studentData,
      currentDictionary: initEmotionDictionary(),
      compareDictionary: initEmotionDictionary(),
      loading: false,
      counter: 0,
      chosenDistrict: userDistrict.district.name,
    };
  } catch (error) {
    console.error("Error in getSuperadminData:", error);
    throw error;
  }
}

//   async function getWellnessData(realmUser, allStudents, isBayless) {
//     let wellnessRef;
//     if (isBayless) {
//       wellnessRef = { wellnesses: [] };
//       let currPage = 1;
//       while (true) {
//         const res = await realmUser.callFunction("fetchAllWellnesses", currPage++);
//         wellnessRef.wellnesses = wellnessRef.wellnesses.concat(res);
//         if (res.length !== 50000) {
//           break;
//         }
//       }
//       const temp = allStudents.map((s) => s._id);
//       wellnessRef.wellnesses = wellnessRef.wellnesses.filter((w) =>
//         temp.includes(w.userID)
//       );
//     } else {
//       wellnessRef = await loadWellnesses(
//         { userID_in: allStudents.map((s) => s._id) },
//         realmUser
//       );
//     }
//     return wellnessRef;

// import {
//   loadDistrictFromCleverId,
//   loadDistrict,
//   loadProfiles2,
//   fetchSchoolIDsByNames,
//   fetchSchoolsInfoByID,
//   fetchSchoolsInfoByCleverId,
//   getClassAssociationsForStudents2,
// } from "../../realm/graphqlQueries";

// export async function getSuperadminData(state, realmUser, user) {
//   try {
//     const userDistrict = await loadUserDistrict(user, realmUser);
//     const allStudents = await loadAllStudents(user, realmUser);
//     const allStudentIDs = createStudentIDMap(allStudents);

//     const [wellnessData, schoolsInfo] = await Promise.all([
//       getWellnessData(realmUser, allStudents),
//       loadSchoolsInfo(user, userDistrict, realmUser),
//     ]);

//     const studentsClasses = await getClassAssociationsForStudents2(
//       userDistrict.district,
//       schoolsInfo,
//       allStudents,
//       realmUser
//     );

//     return {
//       districts: { [userDistrict.district.name]: studentsClasses },
//       allWellnessPoints: wellnessData.wellnesses,
//       allStudents,
//       studentData: constructStudentData(wellnessData.wellnesses, allStudentIDs),
//       currentDictionary: initEmotionDictionary(),
//       compareDictionary: initEmotionDictionary(),
//       loading: false,
//       counter: 0,
//       chosenDistrict: userDistrict.district.name,
//     };
//   } catch (error) {
//     console.error("Error in getSuperadminData:", error);
//     throw error;
//   }
// }

// async function loadUserDistrict(user, realmUser) {
//   return user.user.cleverId
//     ? await loadDistrictFromCleverId(user.user.district, realmUser)
//     : await loadDistrict(user.user.district, realmUser);
// }

// async function loadAllStudents(user, realmUser) {
//   const allStudentsRef = await loadProfiles2(
//     { district: user.user.district, user: "student" },
//     realmUser
//   );
//   return allStudentsRef.users;
// }

// function createStudentIDMap(allStudents) {
//   return allStudents.reduce((map, student) => {
//     map[student._id] = student.email;
//     return map;
//   }, {});
// }
// // look into fetching this same data on the function but filtering it as we fetch it so we dont have to do that here
// async function getWellnessData(realmUser, allStudents) {
//   const wellnessRef = { wellnesses: [] };
//   const studentIds = new Set(allStudents.map((s) => s._id));
//   let currPage = 1;

//   while (true) {
//     const res = await realmUser.callFunction("fetchAllWellnesses", currPage++);
//     wellnessRef.wellnesses = wellnessRef.wellnesses.concat(
//       res.filter((w) => studentIds.has(w.userID))
//     );
//     if (res.length !== 50000) break;
//   }

//   return wellnessRef;
// }

// async function loadSchoolsInfo(user, userDistrict, realmUser) {
//   if (!user.user.cleverId) {
//     const schoolIDs = await fetchSchoolIDsByNames(
//       userDistrict.district.schools,
//       realmUser
//     );
//     return fetchSchoolsInfoByID(schoolIDs, realmUser);
//   } else {
//     return fetchSchoolsInfoByCleverId(userDistrict.district.schools, realmUser);
//   }
// }

// function constructStudentData(wellnesses, allStudentIDs) {
//   return wellnesses.reduce((studentData, datapoint) => {
//     const studentEmail = allStudentIDs[datapoint.userID];
//     if (!studentData[studentEmail]) studentData[studentEmail] = [];
//     studentData[studentEmail].push(datapoint);
//     return studentData;
//   }, {});
// }

// function initEmotionDictionary() {
//   return {
//     sad: 0,
//     tired: 0,
//     lonely: 0,
//     happy: 0,
//     grateful: 0,
//     calm: 0,
//     stressed: 0,
//     scared: 0,
//     angry: 0,
//   };
// }
