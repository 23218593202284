import React from 'react';

const EmotionsLogHeaderBox = ({ userRole, state  }) => {
  let name = state.studentEmailToNameMap[state.chosenStudent] ? state.studentEmailToNameMap[state.chosenStudent] : state.chosenStudent;

  const renderHeaderContent = () => {
    switch (userRole) {
      case '123wellness':
        return (
          <p className="class-info-text">
            WHO IN: <strong>{state.chosenSchool.toUpperCase()}</strong> IN <strong>{state.chosenDistrict.toUpperCase()}</strong>
          </p>
        );
      case 'superadmin':
        if (state.chosenStudent === "All Students") {
          return (
            <p className="class-info-text">
              WHO IN: <strong>{state.chosenClass.toUpperCase()}</strong> IN <strong>{state.chosenSchool.toUpperCase()}</strong>
            </p>
          );
        } else {
          return (
            <p className="class-info-text">
              HAS <strong>{name.toUpperCase()}</strong> IN <strong>{state.chosenClass.toUpperCase()}</strong> IN <strong>{state.chosenSchool.toUpperCase()}</strong>
            </p>
          );
        }
      case 'admin':
      case 'teacher':
        if (state.chosenStudent === "All Students") {
          return (
            <p className="class-info-text">
              WHO IN: <strong>{state.chosenClass.toUpperCase()}</strong>
            </p>
          );
        } else {
          return (
            <p className="class-info-text">
              HAS <strong>{name.toUpperCase()}</strong> IN <strong>{state.chosenClass.toUpperCase()}</strong>
            </p>
          );
        }
      default:
        return null;
    }
  };

  return (
    <div className="class-info-box">
      {renderHeaderContent()}
    </div>
  );
};

export default EmotionsLogHeaderBox;